import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import {
  CHECK_LIST,
  CODE,
  HEADING,
  LINK,
  ORDERED_LIST,
  QUOTE,
  TEXT_FORMAT_TRANSFORMERS,
  UNORDERED_LIST,
} from "@lexical/markdown"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { useMemo } from "react"

function MarkdownPlugin() {
  const { config } = useLexicalEditorContext()

  const transformers = useMemo(
    () => [
      ...ArrayUtils.spreadIf([HEADING], config.blocks.has("heading")),
      ...ArrayUtils.spreadIf(
        [UNORDERED_LIST, ORDERED_LIST, CHECK_LIST],
        config.blocks.has("list")
      ),
      ...ArrayUtils.spreadIf([QUOTE], config.blocks.has("quote")),
      ...ArrayUtils.spreadIf([CODE], config.blocks.has("code")),
      ...ArrayUtils.spreadIf([LINK], config.inlineTools.has("link")),

      ...TEXT_FORMAT_TRANSFORMERS.filter((transformer) => {
        return transformer.format.every((format) => config.inlineTools.has(format))
      }),
    ],
    [config]
  )

  return <MarkdownShortcutPlugin transformers={transformers} />
}

export default MarkdownPlugin
