import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import TextPlaceholder from "@components/editor/plugins/text-placeholder/TextPlaceholder"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import {
  $getNearestRootOrShadowRoot,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  LexicalCommand,
  LexicalEditor,
  createCommand,
} from "lexical"
import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"

export const RESIZE_START_COMMAND: LexicalCommand<void> =
  createCommand("RESIZE_START_COMMAND")
export const RESIZE_END_COMMAND: LexicalCommand<void> =
  createCommand("RESIZE_END_COMMAND")

function useTextPlaceholder(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  placeholder: string
): JSX.Element | null {
  const [showPlaceholder, setShowPlaceholder] = useState(false)
  const [isResizing, setIsResizing] = useState(false)

  useEffect(() => {
    return editor.registerCommand<DragEvent>(
      RESIZE_START_COMMAND,
      () => {
        // Hide the placeholder while a block is resized
        setIsResizing(true)
        return false
      },
      COMMAND_PRIORITY_HIGH
    )
  }, [editor])

  useEffect(() => {
    return editor.registerCommand<DragEvent>(
      RESIZE_END_COMMAND,
      () => {
        // Show the placeholder after a block is resized
        setIsResizing(false)
        return false
      },
      COMMAND_PRIORITY_HIGH
    )
  }, [editor])

  const updatePlaceholder = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the placeholder when using IME input
      if (editor.isComposing()) {
        return
      }

      // Hide this placeholder when the editor is empty as we will show the editor's placeholder
      if ($getRoot().getTextContent() === "") {
        setShowPlaceholder(false)
        return
      }

      const selection = $getSelection()

      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setShowPlaceholder(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      if (selection.getTextContent() !== "") {
        setShowPlaceholder(false)
        return
      }

      const node = LexicalUtils.getSelectedNode(selection)

      if (!$isParagraphNode(node)) {
        setShowPlaceholder(false)
        return
      }

      // Hide this placeholder when nested in a block node, ie. a table cell
      if ($getNearestRootOrShadowRoot(node).getType() !== "root") {
        setShowPlaceholder(false)
        return
      }

      setShowPlaceholder(node.getChildrenSize() === 0)
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener("selectionchange", updatePlaceholder)
    return () => {
      document.removeEventListener("selectionchange", updatePlaceholder)
    }
  }, [updatePlaceholder])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePlaceholder()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setShowPlaceholder(false)
        }
      })
    )
  }, [editor, updatePlaceholder])

  if (!showPlaceholder || isResizing) return null

  return createPortal(
    <TextPlaceholder editor={editor} anchorElem={anchorElem} placeholder={placeholder} />,
    anchorElem
  )
}

interface TextPlaceholderPluginProps {
  anchorElem?: HTMLElement
  placeholder: string
}

function TextPlaceholderPlugin({
  anchorElem = document.body,
  placeholder,
}: TextPlaceholderPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  return useTextPlaceholder(editor, anchorElem, placeholder)
}

export default TextPlaceholderPlugin
