// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/DragDropPastePlugin/index.tsx
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { INSERT_EMBED_FILE_COMMAND } from "@components/editor/plugins/embeds/EmbedPlugin"
import { INSERT_IMAGE_FILE_COMMAND } from "@components/editor/plugins/image/ImagePlugin"
import { INSERT_VIDEO_FILE_COMMAND } from "@components/editor/plugins/video/VideoPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { DRAG_DROP_PASTE } from "@lexical/rich-text"
import { isMimeType, mediaFileReader } from "@lexical/utils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { COMMAND_PRIORITY_LOW } from "lexical"
import { useEffect, useMemo } from "react"

const ACCEPTABLE_IMAGE_TYPES = ["image/"]
const ACCEPTABLE_VIDEO_TYPES = ["video/"]
const ACCEPTABLE_EMBED_TYPES = ["application/pdf"]

export default function FilePastePlugin(): null {
  const [editor] = useLexicalComposerContext()

  const { config } = useLexicalEditorContext()

  const acceptableFiles = useMemo(
    () => [
      ...ArrayUtils.spreadIf([ACCEPTABLE_IMAGE_TYPES], config.blocks.has("image")),
      ...ArrayUtils.spreadIf([ACCEPTABLE_VIDEO_TYPES], config.blocks.has("video")),
      ...ArrayUtils.spreadIf([ACCEPTABLE_EMBED_TYPES], config.blocks.has("embed")),
    ],
    [config.blocks]
  )

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files) => {
        ;(async () => {
          const filesResult = await mediaFileReader(
            files,
            acceptableFiles.flatMap((x) => x)
          )
          for (const { file } of filesResult) {
            if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
              editor.dispatchCommand(INSERT_IMAGE_FILE_COMMAND, { file })
            }
            if (isMimeType(file, ACCEPTABLE_VIDEO_TYPES)) {
              editor.dispatchCommand(INSERT_VIDEO_FILE_COMMAND, { file })
            }
            if (isMimeType(file, ACCEPTABLE_EMBED_TYPES)) {
              editor.dispatchCommand(INSERT_EMBED_FILE_COMMAND, { file })
            }
          }
        })()
        return true
      },
      COMMAND_PRIORITY_LOW
    )
  }, [editor, acceptableFiles])

  return null
}
