import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import EditorToolbarButton from "@components/editor/plugins/toolbar/EditorToolbarButton"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { FORMAT_TEXT_COMMAND } from "lexical"

interface EditorToolbarTextFormatButtonsProps {
  isBold: boolean
  isItalic: boolean
  isUnderline: boolean
  isStrikethrough: boolean
  isCode: boolean
}

function EditorToolbarTextFormatButtons(props: EditorToolbarTextFormatButtonsProps) {
  const { isBold, isItalic, isUnderline, isStrikethrough, isCode } = props

  const { config } = useLexicalEditorContext()

  const [editor] = useLexicalComposerContext()

  return (
    <>
      {config.inlineTools.has("bold") && (
        <EditorToolbarButton
          isActive={isBold}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
          icon={"bold"}
          tooltip={"Bold"}
          ariaLabel={"Format text as bold"}
        />
      )}
      {config.inlineTools.has("italic") && (
        <EditorToolbarButton
          isActive={isItalic}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
          icon={"italic"}
          tooltip={"Italic"}
          ariaLabel={"Format text as italics"}
        />
      )}
      {config.inlineTools.has("underline") && (
        <EditorToolbarButton
          isActive={isUnderline}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
          icon={"underline"}
          tooltip={"Underline"}
          ariaLabel={"Format text to underlined"}
        />
      )}
      {config.inlineTools.has("strikethrough") && (
        <EditorToolbarButton
          isActive={isStrikethrough}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
          icon={"strikethrough"}
          tooltip={"Strikethrough"}
          ariaLabel={"Format text with a strikethrough"}
        />
      )}
      {config.inlineTools.has("code") && (
        <EditorToolbarButton
          isActive={isCode}
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")}
          icon={"code"}
          tooltip={"Inline Code"}
          ariaLabel={"Format text as inline code"}
        />
      )}
    </>
  )
}

export default EditorToolbarTextFormatButtons
