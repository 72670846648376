// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/FloatingLinkEditorPlugin/index.tsx
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import { DiscoIcon, DiscoIconButton, DiscoInput, DiscoLink, DiscoText } from "@disco-ui"
import { $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $findMatchingParent, mergeRegister } from "@lexical/utils"
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  GridSelection,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical"
import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

const SUPPORTED_URL_PROTOCOLS = ["http:", "https:", "mailto:", "sms:", "tel:"]

export function sanitizeUrl(url: string): string {
  let sanitizedUrl = url.trim()

  try {
    if (!SUPPORTED_URL_PROTOCOLS.some((protocol) => url.startsWith(protocol))) {
      sanitizedUrl = `https://${sanitizedUrl}`
    }
  } catch {
    return sanitizedUrl
  }
  return sanitizedUrl
}

function FloatingLinkEditor({
  editor,
  linkType,
  closeEditor,
  anchorElem,
}: {
  editor: LexicalEditor
  linkType: LinkType | null
  closeEditor: () => void
  anchorElem: HTMLElement
}) {
  const editorRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [linkUrl, setLinkUrl] = useState("")
  const [linkTarget, setLinkTarget] = useState<string | null>(null)
  const [editedLinkUrl, setEditedLinkUrl] = useState("")
  const [isLinkInvalid, setIsLinkInvalid] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [lastSelection, setLastSelection] = useState<
    RangeSelection | GridSelection | NodeSelection | null
  >(null)

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection()

    let url = ""

    if ($isRangeSelection(selection)) {
      const node = LexicalUtils.getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent)) {
        url = parent.getURL()
        setLinkTarget(parent.getTarget())
      } else if ($isLinkNode(node)) {
        url = node.getURL()
        setLinkTarget(node.getTarget())
      } else {
        setLinkUrl("")
        setLinkTarget(null)
      }

      if (url !== linkUrl) {
        setLinkUrl(url)
        setEditMode(false)
      }

      if (url === LexicalUtils.EDITOR_LINK_PLACEHOLDER) {
        setEditMode(true)
        setEditedLinkUrl("")
      }
    }
    const editorElem = editorRef.current
    const nativeSelection = window.getSelection()
    const { activeElement } = document

    if (editorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRect: DOMRect | undefined =
        nativeSelection.focusNode?.parentElement?.getBoundingClientRect()
      if (domRect) {
        domRect.y += 40
        LexicalUtils.setFloatingElemPosition(domRect, editorElem, anchorElem, {
          isLinkEditor: true,
        })
      }
      setLastSelection(selection)
    } else if (!activeElement || activeElement.id !== "link-input") {
      if (rootElement !== null) {
        LexicalUtils.setFloatingElemPosition(null, editorElem, anchorElem, {
          isLinkEditor: true,
        })
      }
      setLastSelection(null)
      setEditMode(false)
      setLinkUrl("")
    }

    return true
  }, [anchorElem, editor, linkUrl])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        updateLinkEditor()
      })
    }

    window.addEventListener("resize", update)
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [anchorElem.parentElement, editor, updateLinkEditor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor()
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (linkType) {
            closeEditor()
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [editor, updateLinkEditor, closeEditor, linkType])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor()
    })
  }, [editor, updateLinkEditor])

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  const handleRemoveLink = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
  }

  const monitorInputInteraction = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleLinkSubmission()
    } else if (event.key === "Escape") {
      event.preventDefault()
      setEditMode(false)
    }
  }

  const handleLinkSubmission = () => {
    if (lastSelection !== null) {
      const sanitizedUrl = sanitizeUrl(editedLinkUrl)

      if (LexicalUtils.validateUrl(sanitizedUrl)) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
          url: sanitizedUrl,
          target: linkTarget,
        })
        setEditMode(false)
      } else {
        setIsLinkInvalid(true)
      }
    }
  }

  const classes = useStyles()

  if (!linkType) return null

  return (
    <div ref={editorRef} className={classes.editor}>
      {renderContent()}
    </div>
  )

  function renderLink() {
    if (isEditMode && linkType === "link") {
      return (
        <div className={classes.linkInput}>
          <DiscoInput
            ref={inputRef}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            id={"link-input"}
            value={editedLinkUrl}
            placeholder={"Add link"}
            onChange={(event) => {
              setEditedLinkUrl(event.target.value)
              setIsLinkInvalid(false)
            }}
            onKeyDown={(event) => monitorInputInteraction(event)}
          />
          {isLinkInvalid && (
            <DiscoText variant={"body-sm"} color={"groovy.red.500"} marginTop={0.5}>
              {"Please provide valid link"}
            </DiscoText>
          )}
        </div>
      )
    }

    return (
      <DiscoLink
        className={classes.link}
        href={sanitizeUrl(linkUrl)}
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        {linkUrl}
      </DiscoLink>
    )
  }

  function renderDeleteButton() {
    // Can not delete auto-links
    if (linkType !== "link") return null

    return (
      <DiscoIconButton
        onMouseDown={(event) => event.preventDefault()}
        onClick={handleRemoveLink}
        size={"small"}
      >
        <DiscoIcon icon={"trash"} />
      </DiscoIconButton>
    )
  }

  function renderContent() {
    if (isEditMode)
      return (
        <div className={classes.container}>
          <div className={classes.linkDetails}>
            {renderLink()}
            <label className={classes.checkboxInput} htmlFor={"link-target-checkbox"}>
              <input
                id={"link-target-checkbox"}
                checked={linkTarget === "_blank"}
                type={"checkbox"}
                onChange={(event) => {
                  if (event.target.checked) setLinkTarget("_blank")
                  else setLinkTarget(null)
                }}
              />
              <DiscoText color={"text.secondary"} variant={"body-sm"}>
                {"Open link in a new tab"}
              </DiscoText>
            </label>
          </div>
          <div className={classes.buttons}>
            <DiscoIconButton
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={(e) => {
                e.preventDefault()
                if (linkUrl === LexicalUtils.EDITOR_LINK_PLACEHOLDER) {
                  handleRemoveLink()
                  closeEditor()
                } else setEditMode(false)
              }}
              size={"small"}
            >
              <DiscoIcon icon={"close"} />
            </DiscoIconButton>
            <DiscoIconButton
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={(e) => {
                e.preventDefault()
                handleLinkSubmission()
              }}
              size={"small"}
            >
              <DiscoIcon icon={"check"} />
            </DiscoIconButton>
          </div>
        </div>
      )

    return (
      <div className={classes.container}>
        <div className={classes.linkDetails}>
          {renderLink()}
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {linkTarget === "_blank" ? "Opens in a new tab" : "Opens in the same tab"}
          </DiscoText>
        </div>
        <div className={classes.buttons}>
          <DiscoIconButton
            onMouseDown={(e) => {
              e.preventDefault()
            }}
            onClick={(e) => {
              e.preventDefault()
              setEditedLinkUrl(linkUrl)
              setEditMode(true)
            }}
            size={"small"}
          >
            <DiscoIcon icon={"edit"} />
          </DiscoIconButton>
          {renderDeleteButton()}
        </div>
      </div>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  editor: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.floatingBar,
    maxWidth: "400px",
    width: "100%",
    opacity: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.big,
    transition: "opacity 0.5s",
    willChange: "transform",
  },
  container: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr max-content",
    padding: theme.spacing(1, 1.5),
    gap: theme.spacing(2),
    alignItems: "start",
  },
  linkDetails: {
    display: "grid",
    gap: theme.spacing(0.5),
  },
  checkboxInput: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    justifySelf: "start",
    userSelect: "none",

    "&, & *": {
      cursor: "pointer",
    },
  },
  linkInput: {
    display: "grid",
  },
  link: {
    display: "flex",
    alignItems: "center",
    wordBreak: "break-all",
    padding: theme.spacing(0.5, 0),
  },
  buttons: {
    display: "flex",
    marginTop: "5px",
    gap: theme.spacing(0.5),
  },
}))

type LinkType = "link" | "auto-link"

function useFloatingLinkEditorToolbar(
  editor: LexicalEditor,
  anchorElem: HTMLElement
): JSX.Element | null {
  const [activeEditor, setActiveEditor] = useState(editor)
  const [linkType, setLinkType] = useState<LinkType | null>(null)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = LexicalUtils.getSelectedNode(selection)
      const linkParent = $findMatchingParent(node, $isLinkNode)
      const autoLinkParent = $findMatchingParent(node, $isAutoLinkNode)

      if (autoLinkParent) {
        setLinkType("auto-link")
      } else if (linkParent) {
        setLinkType("link")
      } else {
        setLinkType(null)
      }
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar()
          setActiveEditor(newEditor)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [editor, updateToolbar])

  return createPortal(
    <FloatingLinkEditor
      editor={activeEditor}
      linkType={linkType}
      anchorElem={anchorElem}
      closeEditor={() => setLinkType(null)}
    />,
    anchorElem
  )
}

export default function FloatingLinkEditorPlugin({
  anchorElem = document.body,
}: {
  anchorElem?: HTMLElement
}): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  return useFloatingLinkEditorToolbar(editor, anchorElem)
}
