// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ImagesPlugin/index.tsx
import {
  $createImageNode,
  $isImageNode,
  ImageNode,
  ImagePayload,
} from "@components/editor/plugins/image/ImageNode"
import {
  $createImageUploadFileNode,
  ImageUploadFileNode,
  ImageUploadFilePayload,
} from "@components/editor/plugins/image/ImageUploadFileNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils"
import {
  $createRangeSelection,
  $getNodeByKey,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  LexicalCommand,
  LexicalEditor,
  createCommand,
} from "lexical"
import { useEffect } from "react"

const getDOMSelection = (targetWindow: Window | null): Selection | null =>
  (targetWindow || window).getSelection()

export type InsertImagePayload = Readonly<ImagePayload>

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand("INSERT_IMAGE_COMMAND")

export type InsertImageFilePayload = Readonly<ImageUploadFilePayload>

export const INSERT_IMAGE_FILE_COMMAND: LexicalCommand<InsertImageFilePayload> =
  createCommand("INSERT_IMAGE_FILE_COMMAND")

export default function ImagePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ImageNode, ImageUploadFileNode])) {
      throw new Error("ImagePlugin: ImageNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload)
          $insertNodeToNearestRoot(imageNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<InsertImageFilePayload>(
        INSERT_IMAGE_FILE_COMMAND,
        (payload) => {
          const imageFileNode = $createImageUploadFileNode(payload)
          $insertNodeToNearestRoot(imageFileNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<DragEvent>(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event)
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<DragEvent>(
        DROP_COMMAND,
        (event) => {
          return onDrop(event, editor)
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [editor])

  return null
}

function onDragover(event: DragEvent): boolean {
  const data = getDragImageData(event)

  if (!data) {
    return false
  }

  const node = getImageNodeInEventData(data)
  if (!node) {
    return false
  }

  if (!canDropImage(event)) {
    event.preventDefault()
  }
  return true
}

function onDrop(event: DragEvent, editor: LexicalEditor): boolean {
  const data = getDragImageData(event)
  if (!data) {
    return false
  }

  const node = getImageNodeInEventData(data)
  if (!node) {
    return false
  }
  event.preventDefault()
  if (canDropImage(event)) {
    const range = getDragSelection(event)
    node.remove()
    const rangeSelection = $createRangeSelection()
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range)
    }
    $setSelection(rangeSelection)
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data)
  }
  return true
}

function getImageNodeInEventData(data: Readonly<ImagePayload>): ImageNode | null {
  const nodeKey = data.key
  if (!nodeKey) return null
  const node = $getNodeByKey(nodeKey)
  return $isImageNode(node) ? node : null
}

function getDragImageData(event: DragEvent): null | InsertImagePayload {
  const dragData = event.dataTransfer?.getData("application/x-lexical-drag")
  if (!dragData) {
    return null
  }
  const { type, data } = JSON.parse(dragData)
  if (type !== "image") {
    return null
  }

  return data
}

declare global {
  interface DragEvent {
    rangeOffset?: number
    rangeParent?: Node
  }
}

function canDropImage(event: DragEvent): boolean {
  const { target } = event
  return Boolean(
    target &&
      target instanceof HTMLElement &&
      !target.closest("code, span.editor-image") &&
      target.parentElement &&
      target.parentElement.closest("div#editor-content-editable")
  )
}

function getDragSelection(event: DragEvent): Range | null | undefined {
  let range
  const target = event.target as null | Element | Document
  const targetWindow =
    target == null
      ? null
      : target.nodeType === 9
      ? (target as Document).defaultView
      : (target as Element).ownerDocument.defaultView
  const domSelection = getDOMSelection(targetWindow)
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY)
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0)
    range = domSelection.getRangeAt(0)
  } else {
    throw Error(`Cannot get the selection when dragging`)
  }

  return range
}
