import { LoginSource } from "@/authentication/redirect/util/__generated__/useCompleteSSOLoginMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { LocationState } from "@/core/route/util/routeUtils"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { SocialLoginDestination } from "@components/social-login/SocialLogin"
import { SSOLoginGetSSOLoginRedirectUrlMutation } from "@components/sso-login/__generated__/SSOLoginGetSSOLoginRedirectUrlMutation.graphql"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { Grid } from "@material-ui/core"
import { StytchB2BHeadlessClient } from "@stytch/vanilla-js/b2b/headless"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"
import { encodeBase64 } from "stream-chat"
import SSOLoginButton from "./SSOLoginButton"

export interface SSOLoginProps {
  loginSource: LoginSource
  redirectDestination?: string
  labelPrefix?: string
  disabled?: boolean
  width?: string
  onClick?: () => boolean
  children?: React.ReactNode
}

const stytch = new StytchB2BHeadlessClient(STYTCH_B2B_PUBLIC_TOKEN)

function SSOLogin(props: SSOLoginProps) {
  const activeOrganization = useActiveOrganization()
  const {
    redirectDestination,
    labelPrefix,
    disabled,
    width,
    onClick,
    children,
    loginSource,
  } = props

  const [isSubmitting, setIsSubmitting] = useState(false)
  const classes = useStyles()
  const location = useLocation<LocationState>()

  if (!location.state) {
    location.state = {}
  }

  // Setup the redirection, by default it is where we are now
  location.state.redirectUrl = redirectDestination || window.location.href

  const destination: SocialLoginDestination = {
    locationState: location.state,
  }

  const getSSOLoginRedirectUrl =
    Relay.useAsyncMutation<SSOLoginGetSSOLoginRedirectUrlMutation>(graphql`
      mutation SSOLoginGetSSOLoginRedirectUrlMutation {
        getSSOLoginRedirectUrl {
          data
          errors {
            field
            message
          }
        }
      }
    `)

  return (
    <>
      {activeOrganization?.authProvider?.connectionId && (
        <>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            className={classes.itemContainer}
          >
            <SSOLoginButton
              labelPrefix={labelPrefix}
              disabled={disabled || isSubmitting}
              width={width}
              onClick={startSSOAuth}
            />
          </Grid>
          {children}
        </>
      )}
    </>
  )

  async function startSSOAuth() {
    if (onClick && !onClick()) {
      return
    }
    if (!activeOrganization?.authProvider) return

    setIsSubmitting(true)

    const res = await getSSOLoginRedirectUrl({})
    if (res.getSSOLoginRedirectUrl.data) {
      const u = new URL(res.getSSOLoginRedirectUrl.data)

      u.searchParams.set("destination", encodeBase64(JSON.stringify(destination)))
      u.searchParams.set("loginSource", loginSource)

      const redirectUrl = u.toString()

      await stytch.sso.start({
        connection_id: activeOrganization.authProvider.connectionId!,
        login_redirect_url: redirectUrl,
        signup_redirect_url: redirectUrl,
      })
    } else {
      let errorMessage = "Unknown error has occurred, please try again later."
      if (res.getSSOLoginRedirectUrl.errors?.length) {
        errorMessage = res.getSSOLoginRedirectUrl.errors[0].message
      }
      displayErrorToast(errorMessage)
    }
    setIsSubmitting(false)
  }
}

export default SSOLogin

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    background: theme.palette.background.paper,
    borderBottomLeftRadius: theme.measure.borderRadius.medium,
    borderBottomRightRadius: theme.measure.borderRadius.medium,
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
