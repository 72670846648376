import UploadMediaModal from "@/media/add/UploadMediaModal"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { VIDEO_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { INSERT_VIDEO_COMMAND } from "@components/editor/plugins/video/VideoPlugin"
import { useEditorElementAnchor } from "@components/editor/utils/useEditorElementAnchor"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useRef } from "react"

interface EditorVideoUploadModalProps extends TestIDProps {
  isOpen: boolean
  onClose: VoidFunction
  anchorElem: HTMLElement
}

function EditorVideoUploadModal({
  isOpen,
  onClose,
  anchorElem,
}: EditorVideoUploadModalProps) {
  const [editor] = useLexicalComposerContext()

  const modalAnchorRef = useRef<HTMLDivElement | null>(null)

  useEditorElementAnchor({
    editorAnchorElem: anchorElem,
    elementAnchorRef: modalAnchorRef,
  })

  const classes = useStyles()

  return (
    <>
      <div ref={modalAnchorRef} className={classes.anchor} />
      {isOpen && modalAnchorRef.current && (
        <UploadMediaModal
          onClose={onClose}
          onUpload={handleResult}
          onMediaSelect={handleResult}
          dropzoneOptions={{ accept: VIDEO_FILE_TYPES }}
          allowedFileTypes={["video"]}
          PopoverProps={{
            anchorEl: modalAnchorRef.current,
            anchorOrigin: {
              vertical: "center",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        />
      )}
    </>
  )

  function handleResult(result: MediaResult) {
    editor.dispatchCommand(INSERT_VIDEO_COMMAND, {
      src: result.url,
      assetId: result.id,
    })
    onClose()
  }
}

const useStyles = makeUseStyles({
  anchor: {
    position: "absolute",
    top: 0,
    left: 0,
  },
})

export default observer(EditorVideoUploadModal)
