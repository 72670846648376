import AppleLogo from "@assets/disco/icons/color-logo/logo-apple.svg"
import GoogleLogo from "@assets/disco/icons/color-logo/logo-google.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { SocialLoginProvider } from "@components/social-login/SocialLogin"
import { DiscoButton, DiscoCustomIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { toTitleCase } from "@utils/string/stringUtils"

export interface SocialAuthProviderButtonProps {
  provider: SocialLoginProvider
  width?: string
  disabled?: boolean
  labelPrefix?: string
  onClick: () => void
}

function SocialAuthProviderButton(props: SocialAuthProviderButtonProps) {
  const { provider, disabled, onClick, labelPrefix, width } = props

  const classes = useStyles()
  const theme = useTheme()

  const logo = getLogoForAuthProvider()

  return (
    <DiscoTooltip
      disabled={!disabled}
      content={"Please accept the terms and conditions above before continuing."}
    >
      <DiscoButton
        disabled={disabled}
        className={classes.button}
        customButtonColor={{
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          hover: { backgroundColor: theme.palette.groovy.neutral[300] },
        }}
        onClick={onClick}
        data-testid={`SocialAuthProviderButton.${provider}`}
        width={width}
      >
        <DiscoCustomIcon icon={logo} />
        <DiscoText
          variant={"body-md-600"}
          marginLeft={2}
          color={theme.palette.type === "dark" ? "common.white" : "common.black"}
        >
          {`${labelPrefix ?? `Continue`} with ${toTitleCase(provider)}`}
        </DiscoText>
      </DiscoButton>
    </DiscoTooltip>
  )

  function getLogoForAuthProvider() {
    switch (provider) {
      case SocialLoginProvider.google: {
        return <GoogleLogo />
      }
      case SocialLoginProvider.apple: {
        return (
          <AppleLogo
            fill={
              theme.palette.type === "dark"
                ? theme.palette.common.white
                : theme.palette.common.black
            }
          />
        )
      }
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default SocialAuthProviderButton
