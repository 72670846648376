import {
  $createVideoNode,
  VideoNode,
  VideoPayload,
} from "@components/editor/plugins/video/VideoNode"
import {
  $createVideoUploadFileNode,
  VideoUploadFileNode,
  VideoUploadFilePayload,
} from "@components/editor/plugins/video/VideoUploadFileNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils"
import { COMMAND_PRIORITY_EDITOR, LexicalCommand, createCommand } from "lexical"
import { useEffect } from "react"

export type InsertVideoPayload = Readonly<VideoPayload>

export const INSERT_VIDEO_COMMAND: LexicalCommand<InsertVideoPayload> =
  createCommand("INSERT_VIDEO_COMMAND")

export type InsertVideoFilePayload = Readonly<VideoUploadFilePayload>

export const INSERT_VIDEO_FILE_COMMAND: LexicalCommand<InsertVideoFilePayload> =
  createCommand("INSERT_VIDEO_FILE_COMMAND")

export default function VideoPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([VideoNode, VideoUploadFileNode])) {
      throw new Error("VideoPlugin: VideoNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<InsertVideoPayload>(
        INSERT_VIDEO_COMMAND,
        (payload) => {
          const videoNode = $createVideoNode(payload)
          $insertNodeToNearestRoot(videoNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<InsertVideoFilePayload>(
        INSERT_VIDEO_FILE_COMMAND,
        (payload) => {
          const videoFileNode = $createVideoUploadFileNode(payload)
          $insertNodeToNearestRoot(videoFileNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  return null
}
