/* 
  This node is used to override the default behaviours of some pasted elements.

  For example, the LexicalTextNode does not handle pasting text color and highlight color.
  Solution from Discord: https://discord.com/channels/953974421008293909/955972012541628456/997279928779292733
*/
import {
  $isTextNode,
  DOMConversionMap,
  DOMConversionOutput,
  ElementNode,
  SerializedElementNode,
} from "lexical"

export class TextPasteNode extends ElementNode {
  static getType(): string {
    return "text-paste"
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: () => ({
        conversion: convertSpanElement,
        priority: 1,
      }),
    }
  }

  static clone(): TextPasteNode {
    return new TextPasteNode()
  }

  static importJSON(): TextPasteNode {
    const node = new TextPasteNode()
    return node
  }

  exportJSON(): SerializedElementNode {
    return super.exportJSON()
  }
}

function convertSpanElement(domNode: Node): DOMConversionOutput {
  // domNode is a <span> since we matched it by nodeName
  const span = domNode as HTMLSpanElement
  // Google Docs uses span tags + font-weight for bold text
  const hasBoldFontWeight = span.style.fontWeight === "700"
  // Google Docs uses span tags + text-decoration: line-through for strikethrough text
  const hasLinethroughTextDecoration = span.style.textDecoration === "line-through"
  // Google Docs uses span tags + font-style for italic text
  const hasItalicFontStyle = span.style.fontStyle === "italic"
  // Google Docs uses span tags + text-decoration: underline for underline text
  const hasUnderlineTextDecoration = span.style.textDecoration === "underline"
  // Google Docs uses span tags + color, background-color for coloring
  const { backgroundColor, color: textColor } = span.style

  return {
    forChild: (lexicalNode) => {
      if (!$isTextNode(lexicalNode)) {
        return lexicalNode
      }
      if (hasBoldFontWeight) {
        lexicalNode.toggleFormat("bold")
      }
      if (hasLinethroughTextDecoration) {
        lexicalNode.toggleFormat("strikethrough")
      }
      if (hasItalicFontStyle) {
        lexicalNode.toggleFormat("italic")
      }
      if (hasUnderlineTextDecoration) {
        lexicalNode.toggleFormat("underline")
      }

      /* 
        Reintroduce what was removed in: https://github.com/facebook/lexical/pull/2663/files

        Will work for pasting from Google Docs and if pasting a selected text from Notion
        NOTE: Pasted HTML does not include formatted text when copying multiple text blocks from Notion
      */
      let cssString = ""

      if (textColor && textColor !== "rgb(0, 0, 0)") {
        cssString += `color: ${textColor};`
      }
      if (backgroundColor && backgroundColor !== "transparent") {
        cssString += `background-color: ${backgroundColor};`
      }

      if (cssString !== "") {
        lexicalNode.setStyle(cssString)
      }

      return lexicalNode
    },
    node: null,
  }
}
