import { GlobalID } from "@/relay/RelayTypes"
import PollWebForm from "@/web-form/filler/poll/PollWebForm"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { NodeKey } from "lexical"

type Props = {
  nodeKey: NodeKey
  webFormId: GlobalID
}

export default function EditorPoll(props: Props) {
  const { webFormId } = props
  const classes = useStyles()
  const { editorUsageData } = useLexicalEditorContext()

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={classes.container}
      // Stop clicks in the poll opening the post drawer
      onClick={(e) => e.stopPropagation()}
    >
      <PollWebForm
        webFormId={webFormId}
        // Don't allow submissions until the content is saved
        disabled={!editorUsageData.contentId}
      />
    </div>
  )
}

const useStyles = makeUseStyles({
  container: {
    cursor: "default",
    // Pad 4px on sides so box shadow doesn't get cut off by editor
    padding: "0 4px",
  },
})
