import makeUseStyles from "@assets/style/util/makeUseStyles"
import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import { $createVideoNode } from "@components/editor/plugins/video/VideoNode"
import { $isVideoUploadFileNode } from "@components/editor/plugins/video/VideoUploadFileNode"
import useMultipartUploadMediaToS3 from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoText } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { $getNodeByKey, NodeKey } from "lexical"
import { useEffect } from "react"

interface EditorVideoUploadFileProps extends TestIDProps {
  nodeKey: NodeKey
  file: File
}

function EditorVideoUploadFile({ nodeKey, file }: EditorVideoUploadFileProps) {
  const theme = useTheme()

  const [editor] = useLexicalComposerContext()

  // When a file is pasted/dropped in we need to upload it then replace the node with an video node
  const { uploadMediaToS3, uploadProgress } = useMultipartUploadMediaToS3()

  useEffect(() => {
    if (file) {
      uploadMediaToS3({ mediaFile: file }).then((result) => {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey)
          if ($isVideoUploadFileNode(node)) {
            const videoNode = $createVideoNode({
              src: result.url,
              assetId: result.id,
            })
            node.replace(videoNode)
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  return (
    <div className={classes.processingContainer}>
      <CircularProgressWithLabel
        color={theme.palette.primary.main}
        value={uploadProgress ?? 0}
      />
      <div>
        <DiscoText variant={"body-md-600"}>{"Uploading Video..."}</DiscoText>
        <DiscoText variant={"body-sm"}>
          {"Your video is currently being uploaded"}
        </DiscoText>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  processingContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.groovy.grey[100],
    borderRadius: theme.measure.borderRadius.big,
    alignItems: "center",
  },
}))

export default EditorVideoUploadFile
