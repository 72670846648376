import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import VideoIcon from "@/core/ui/iconsax/linear/video.svg"
import { FEEDBACK_MODAL_TIMEOUT_MS } from "@/product/course/occurrence/EventFeedbackButton"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { JoinEventButtonQuery } from "@components/join-event-button/__generated__/JoinEventButtonQuery.graphql"
import { JoinEventButton_AttendOccurrenceMutation } from "@components/join-event-button/__generated__/JoinEventButton_AttendOccurrenceMutation.graphql"
import { DiscoButton, DiscoButtonProps, DiscoButtonSkeleton } from "@disco-ui"
import Link from "@material-ui/core/Link"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { setSearchParams } from "@utils/url/urlUtils"
import React from "react"
import { commitLocalUpdate, graphql, useLazyLoadQuery } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"
import {
  haveAnHourToOccurrence,
  isOccurrenceLive,
} from "../../occurrence/util/occurrenceUtils"

interface JoinEventButtonProps extends Pick<DiscoButtonProps, "width"> {
  testid: string
  occurrenceId: GlobalID
  label?: string
  className?: string
  hideIfEventOverOrUpcoming?: boolean
  hideIcon?: boolean
  onClick?: () => void
}

function JoinEventButton({
  testid,
  occurrenceId,
  label,
  className,
  // Option to hide the disabled `Join Event` button
  hideIfEventOverOrUpcoming = false,
  hideIcon = false,
  onClick,
  width,
}: JoinEventButtonProps) {
  const location = useLocation()
  const history = useHistory()
  const { authUser } = useAuthUser()
  const activeProduct = useActiveProduct()
  const isMobile = useIsMobile()

  const { occurrence } = useLazyLoadQuery<JoinEventButtonQuery>(
    graphql`
      query JoinEventButtonQuery($id: ID!) {
        occurrence: node(id: $id) {
          id
          ... on Occurrence {
            meetingUrl
            collectFeedback
            datetimeRange
            eventId
            hasViewerSubmittedFeedback
            product {
              id
            }
            atCapacity
            hasViewerRSVPd
            event {
              ...usePermissionsFragment
            }
          }
        }
      }
    `,
    { id: occurrenceId }
  )

  const attendOccurrence =
    Relay.useAsyncMutation<JoinEventButton_AttendOccurrenceMutation>(
      graphql`
        mutation JoinEventButton_AttendOccurrenceMutation(
          $input: AttendOccurrenceInput!
        ) {
          response: attendOccurrence(input: $input) {
            attendance {
              id
              attendedAt
            }
          }
        }
      `
    )

  const canManage = usePermissions(occurrence?.event).has("events.manage")

  if (!occurrence) return null

  const isLive =
    haveAnHourToOccurrence(occurrence?.datetimeRange) ||
    isOccurrenceLive(occurrence?.datetimeRange)

  const productId = activeProduct?.id || occurrence.product?.id

  if (!productId)
    throw new Error(
      "Join Event Button requires an activeProduct or product.id from the occurrence"
    )

  const buttonLabel =
    label ||
    (canManage
      ? `Join${isMobile ? "" : " Event"}`
      : occurrence.hasViewerRSVPd
      ? `Join${isMobile ? "" : " Event"}`
      : occurrence.atCapacity
      ? `At Capacity`
      : "")

  const isDisabled = !canManage && !occurrence.hasViewerRSVPd && occurrence.atCapacity

  return (
    <>
      {isLive ? (
        <DiscoButton
          data-testid={testid}
          className={className}
          component={Link}
          underline={"none"}
          leftIcon={hideIcon ? undefined : <VideoIcon />}
          color={"primary"}
          onClick={handleJoinEventClick}
          disabled={isDisabled}
          width={width}
        >
          {buttonLabel}
        </DiscoButton>
      ) : hideIfEventOverOrUpcoming ? null : (
        <DiscoButton
          data-testid={testid}
          className={className}
          leftIcon={<VideoIcon />}
          color={isLive ? "error" : "primary"}
          disabled={true}
          onClick={onClick}
          width={width}
        >
          {buttonLabel}
        </DiscoButton>
      )}
    </>
  )

  async function handleJoinEventClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()

    if (isDisabled || !occurrence?.meetingUrl) return
    window.open(occurrence.meetingUrl, "_blank")

    if (!authUser || !occurrence?.eventId || !productId) return

    const { response } = await attendOccurrence({ input: { occurrenceId } })
    if (response?.attendance?.id) {
      setTimeout(() => {
        // trigger the feedback modal to automatically open after 10 minutes
        history.replace({
          ...location,
          search: setSearchParams(location.search, { fb: "1" }),
        })
      }, FEEDBACK_MODAL_TIMEOUT_MS)

      // set viewerDidAttend so the feedback modal can be opened
      commitLocalUpdate(RelayEnvironment, (store) => {
        const o = store.get(occurrenceId)
        if (!o) return
        o.setValue(true, "didViewerAttend")
      })
    }
    if (onClick) onClick()
  }
}

export default Relay.withSkeleton<JoinEventButtonProps>({
  component: JoinEventButton,
  skeleton: DiscoButtonSkeleton,
})
