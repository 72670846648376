/** Check if there is more content hidden below, or if a container is scrolled to the bottom */
export function isMoreContent(componentRef: React.MutableRefObject<HTMLElement | null>) {
  const el = componentRef?.current
  if (!el) return false

  // scrollTop is sometimes a decimal, so to be safe, round down comparison values to the nearest integer
  const elementHeight = Math.floor(el.clientHeight)
  const scrolledTo = Math.floor(el.scrollHeight - el.scrollTop)

  // when window is zoomed in/out, the scrolledTo value can be off by 1px, so check within 1px buffer
  const isAtBottom =
    scrolledTo === elementHeight ||
    scrolledTo === elementHeight - 1 ||
    scrolledTo === elementHeight + 1
  if (isAtBottom) return false

  const isMoreBeneath = el.scrollHeight > el.clientHeight
  return isMoreBeneath
}
