import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorTooltip from "@components/editor/components/EditorTooltip"
import {
  DiscoIcon,
  DiscoIconButton,
  DiscoIconButtonProps,
  DiscoIconKinds,
  DiscoText,
} from "@disco-ui"
import { forwardRef } from "react"

interface EditorToolbarButtonProps extends Omit<DiscoIconButtonProps, "children"> {
  isActive: boolean
  icon: React.ReactElement | DiscoIconKinds
  tooltip: string
  ariaLabel: string
}

const EditorToolbarButton = forwardRef<HTMLButtonElement, EditorToolbarButtonProps>(
  ({ isActive, icon, tooltip, ariaLabel, ...rest }, ref) => {
    const classes = useStyles({ isActive })

    return (
      <EditorTooltip
        content={<DiscoText variant={"body-xs-700"}>{tooltip}</DiscoText>}
        placement={"top"}
      >
        <DiscoIconButton
          ref={ref}
          className={classes.button}
          size={"small"}
          aria-label={ariaLabel}
          svgStyles={{ width: 20, height: 20 }}
          {...rest}
        >
          {typeof icon === "string" ? <DiscoIcon icon={icon} /> : icon}
        </DiscoIconButton>
      </EditorTooltip>
    )
  }
)

type StyleProps = {
  isActive: boolean
}

const useStyles = makeUseStyles((theme) => ({
  button: {
    padding: theme.spacing(0.5),
    borderRadius: theme.measure.borderRadius.default,

    "&:hover svg, & svg": {
      color: ({ isActive }: StyleProps) =>
        isActive ? theme.palette.text.primary : theme.palette.constants.icon,
    },

    "&:hover, &:active": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200],
    },
  },
}))

export default EditorToolbarButton
