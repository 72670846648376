import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import {
  RESIZE_END_COMMAND,
  RESIZE_START_COMMAND,
} from "@components/editor/plugins/text-placeholder/TextPlaceholderPlugin"
import EditorToolbar, {
  EditorToolbarGroup,
} from "@components/editor/plugins/toolbar/EditorToolbar"
import EditorToolbarTextAlignButton from "@components/editor/plugins/toolbar/EditorToolbarTextAlignButton"
import { $isVideoNode } from "@components/editor/plugins/video/VideoNode"
import ResizableContainer from "@components/resizable/ResizableContainer"
import DiscoAssetVideo from "@disco-ui/video/DiscoAssetVideo"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { Theme, useMediaQuery } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { $getNodeByKey, ElementFormatType, NodeKey } from "lexical"
import { useState } from "react"

interface EditorVideoProps extends TestIDProps {
  nodeKey: NodeKey
  src: string
  assetId: string
  assetUsageId?: string
  alignment?: ElementFormatType
  widthPercentage?: number
}

function EditorVideo({
  nodeKey,
  src,
  assetId,
  assetUsageId,
  alignment = "left",
  widthPercentage,
}: EditorVideoProps) {
  const [isToolbarOpen, setIsToolbarOpen] = useState(false)
  const classes = useStyles({ widthPercentage, alignment, isToolbarOpen })
  const isXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  const { config, editorUsageData } = useLexicalEditorContext()

  const [editor] = useLexicalComposerContext()
  const isEditable = editor.isEditable()

  const setWidthPercentage = (percentage: number) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isVideoNode(node)) {
        node.setWidthPercentage(percentage)
      }
    })
  }

  const setAlignment = (option: ElementFormatType) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isVideoNode(node)) {
        node.setAlignment(option)
      }
    })
  }

  if (!isEditable) {
    return (
      <div className={classes.container}>
        <DiscoAssetVideo
          src={src}
          assetId={assetId}
          assetUsageId={assetUsageId}
          contentId={editorUsageData?.contentId}
          contentUsageId={editorUsageData?.contentUsageId}
          trackWatchProgress // Only track watch progress in read only mode
          className={classes.video}
          maxHeight={"unset"}
          fullWidth
        />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <ResizableContainer
        lockAspectRatio
        maxWidth={"100%"}
        minWidth={"25%"}
        size={
          widthPercentage
            ? {
                height: "auto",
                width: isXsDown ? "100%" : `${widthPercentage}%`,
              }
            : {
                height: "auto",
                width: "100%",
              }
        }
        onResizeStart={() => {
          editor.dispatchCommand(RESIZE_START_COMMAND, undefined)
        }}
        onResizeStop={(_e, _direction, ref) => {
          editor.dispatchCommand(RESIZE_END_COMMAND, undefined)
          const widthPercent = Math.round(
            (ref.clientWidth / ref!.parentElement!.clientWidth) * 100
          )
          setWidthPercentage(widthPercent)
        }}
      >
        <DiscoAssetVideo
          src={src}
          assetId={assetId}
          trackWatchProgress={false} // Don't track watch progress in create/edit modes
          maxHeight={"unset"}
          fullWidth
        />
        {renderOptions()}
      </ResizableContainer>
    </div>
  )

  function renderOptions() {
    if (!config.inlineTools.has("alignment")) return null

    return (
      <EditorToolbar
        className={classes.toolbar}
        onDropdownOpen={() => setIsToolbarOpen(true)}
        onDropdownClose={() => setIsToolbarOpen(false)}
      >
        <EditorToolbarGroup>
          <EditorToolbarTextAlignButton alignment={alignment} onChange={setAlignment} />
        </EditorToolbarGroup>
      </EditorToolbar>
    )
  }
}

type StyleProps = {
  widthPercentage?: number
  alignment: ElementFormatType
  isToolbarOpen: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: ({ alignment }: StyleProps) => {
      switch (alignment) {
        case "center":
          return "center"
        case "right":
          return "flex-end"
        default:
          return "flex-start"
      }
    },

    // hide toolbar when not hovered or when toolbar is closed
    "& $toolbar": {
      opacity: ({ isToolbarOpen }: StyleProps) => (isToolbarOpen ? 1 : 0),
    },
    "&:hover $toolbar": {
      opacity: 1,
    },
  },
  toolbar: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  video: ({ widthPercentage }: StyleProps) => ({
    height: "auto",
    width: widthPercentage ? `${widthPercentage}%` : "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }),
}))

export default EditorVideo
