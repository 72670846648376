import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"

type PulsatingDotProps = {
  testid?: string
  className?: string
}

function PulsatingDot(props: PulsatingDotProps) {
  const { testid, className } = props
  const classes = useStyles()
  return (
    <div className={classNames(classes.container, className)} data-testid={testid}>
      <div className={classes.ring} />
      <div className={classes.circle} />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    display: "inline-block",
    width: "20px",
    height: "20px",
  },
  circle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "10px",
    height: "10px",
    backgroundColor: theme.palette.error.main,
    borderRadius: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },

  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes pulsate": {
    "0%": {
      opacity: 0,
      transform: "scale(0.1, 0.1)",
    },

    "50%": {
      opacity: 1,
    },

    "100%": {
      opacity: 0,
      transform: "scale(1.2, 1.2)",
    },
  },
  ring: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "20px",
    height: "20px",
    border: `3px solid ${theme.palette.error.main}`,
    borderRadius: "50%",
    opacity: 0,
    animationName: "$pulsate",
    animationDuration: "1s",
    animationTimingFunction: "ease-out",
    animationIterationCount: "infinite",
  },
}))

export default PulsatingDot
