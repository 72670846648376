import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import useIsWebView from "@/product/util/hook/useIsWebView"
import ThreeDotLoader from "@assets/images/onboarding/processing/three-dot-loader.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useEffect } from "react"

/** Global triple dot loader for any place we want to show a standard loading page */
function LoadingPage() {
  const isWebView = useIsWebView()
  const classes = useStyles()

  // Send a message to the webview that the frontend is ready
  // when this screen is unmounted
  useEffect(() => {
    return () => {
      if (!isWebView) return
      postWebViewMessage({
        type: WebViewMessageType.frontend_ready,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render a blank page if in a webview
  if (isWebView) return <div className={classes.webView} />

  return (
    <div className={classes.container}>
      <ThreeDotLoader />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  webView: {
    height: "100dvh",
    backgroundColor: theme.palette.common.white,
  },
  container: {
    height: "100dvh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default LoadingPage
