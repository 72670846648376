import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useViewerCanCreateEventsFragment$key } from "@components/global-add/__generated__/useViewerCanCreateEventsFragment.graphql"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

/*
  This hook checks if the viewer can create events in the current organization whether they have access
  to create community events or they are an admin of a course with the events app enabled.
*/
export function useViewerCanCreateEvents(
  organizationMembershipKey?: useViewerCanCreateEventsFragment$key | null
) {
  const activeOrganization = useActiveOrganization()

  const organizationMembership = useFragment<useViewerCanCreateEventsFragment$key>(
    graphql`
      fragment useViewerCanCreateEventsFragment on OrganizationMembership {
        adminMembershipsWithEventApps: productMemberships(
          roles: [manager, instructor]
          productType: "course"
          appKind: events
        ) {
          totalCount
        }
      }
    `,
    organizationMembershipKey || null
  )

  return Boolean(
    activeOrganization?.viewerPermissions.has("events.manage") ||
      organizationMembership?.adminMembershipsWithEventApps?.totalCount
  )
}
