import { PollNode } from "@components/editor/plugins/poll/PollNode"
import { UPDATE_POLL_COMMAND } from "@components/editor/plugins/poll/PollPlugin"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

type Props = {
  pollNode: PollNode
}

export default function PollBlockOptions({ pollNode }: Props) {
  const [editor] = useLexicalComposerContext()

  return (
    <DiscoDropdownItem
      testid={"PollBlockOptions.dropdown.edit"}
      onClick={openSettings}
      title={"Edit Poll Settings"}
      icon={"settings"}
    />
  )

  function openSettings() {
    editor.getEditorState().read(() => {
      editor.dispatchCommand(UPDATE_POLL_COMMAND, {
        webFormId: pollNode.getWebFormId(),
      })
    })
  }
}
