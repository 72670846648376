import useUnreadNotifications from "@/apps/util/hooks/useUnreadNotifications"
import { NotificationFilter } from "@/core/context/NotificationsContext"
import useStreamChannelsTotalUnreadCount from "@components/chat/channel/util/useStreamChannelsTotalUnreadCount"
import useStreamTotalUnreadCount from "@components/chat/channel/util/useStreamTotalUnreadCount"
import CountBadge, { CountBadgeProps } from "@components/square-count-badge/CountBadge"
import { Channel } from "stream-chat"

export type NotificationBadgeProps = Omit<CountBadgeProps, "count"> & {
  config?: {
    filter?: Partial<NotificationFilter>
    channels?: Channel[]
    streamChannelIds?: string[]
    count?: number
  }
}

function NotificationBadge({ config = {}, ...rest }: NotificationBadgeProps) {
  const inAppNotificationCount = useUnreadNotifications(config?.filter || null).length
  const chatNotificationCount = useStreamChannelsTotalUnreadCount(config?.channels || [])
  const totalUnreadCount = useStreamTotalUnreadCount(config?.streamChannelIds || [])
  const countValue =
    inAppNotificationCount +
    chatNotificationCount +
    totalUnreadCount +
    (config.count ?? 0)

  if (countValue <= 0) return null

  return <CountBadge {...rest} count={countValue} />
}

export default NotificationBadge
