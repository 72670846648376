import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"

export interface SSOLoginButtonProps {
  width?: string
  disabled?: boolean
  labelPrefix?: string
  onClick: () => void
}

function SSOLoginButton(props: SSOLoginButtonProps) {
  const { width, disabled, labelPrefix, onClick } = props
  const classes = useStyles()
  const theme = useTheme()

  return (
    <DiscoTooltip
      disabled={!disabled}
      content={"Please accept the terms and conditions above before continuing."}
    >
      <DiscoButton
        disabled={disabled}
        className={classes.button}
        customButtonColor={{
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          hover: { backgroundColor: theme.palette.groovy.neutral[300] },
        }}
        onClick={onClick}
        data-testid={"SSOLoginButton"}
        width={width}
      >
        <DiscoIcon icon={"lock"} />
        <DiscoText
          variant={"body-md-600"}
          marginLeft={2}
          color={theme.palette.type === "dark" ? "common.white" : "common.black"}
        >
          {`${labelPrefix ?? `Continue`} with SSO`}
        </DiscoText>
      </DiscoButton>
    </DiscoTooltip>
  )
}

const useStyles = makeUseStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default SSOLoginButton
