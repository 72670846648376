/**
 * @generated SignedSource<<9d4435a87da462b2448833d0f1b5c348>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MultipartUploadInput = {
  uploadId: string;
  partNumber: number;
};
export type useMultipartUploadMediaToS3Mutation$variables = {
  input: MultipartUploadInput;
};
export type useMultipartUploadMediaToS3Mutation$data = {
  readonly response: {
    readonly data: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useMultipartUploadMediaToS3Mutation = {
  variables: useMultipartUploadMediaToS3Mutation$variables;
  response: useMultipartUploadMediaToS3Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMultipartUploadUrlResponse",
    "kind": "LinkedField",
    "name": "createMultipartUploadUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMultipartUploadMediaToS3Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMultipartUploadMediaToS3Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cc20dcc248b6f80d78f63ae42f06cd60",
    "id": null,
    "metadata": {},
    "name": "useMultipartUploadMediaToS3Mutation",
    "operationKind": "mutation",
    "text": "mutation useMultipartUploadMediaToS3Mutation(\n  $input: MultipartUploadInput!\n) {\n  response: createMultipartUploadUrl(input: $input) {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "451341db60e2c76a67ebe16072e36bab";

export default node;
