import { QuestionInput } from "@/admin/registration/__generated__/AdminRegistrationSettingsFormMutation.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoText, DiscoTextButton } from "@disco-ui"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { observer } from "mobx-react-lite"

interface QuestionFormFieldProps {
  label: string
  question: Pick<QuestionInput, "richEditorBody">
  questionOrder: number
  onContentChange: (v: string) => void
  onRemoveQuestion: () => void
  hasErrors?: boolean
}

function QuestionFormField({
  label,
  question,
  questionOrder,
  onContentChange,
  onRemoveQuestion,
  hasErrors,
}: QuestionFormFieldProps) {
  const classes = useStyles()
  const permissions = useActiveProductOrOrganizationPermissions()

  return (
    <div
      className={classes.container}
      data-testid={`QuestionFormField.question-${questionOrder}`}
    >
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1}>
        {label}
      </DiscoText>
      <DiscoEditor
        defaultValue={question.richEditorBody}
        onChange={handleEditorChange}
        readOnly={!permissions.has("registration.manage")}
        config={"question"}
        variant={"input"}
        hasError={hasErrors}
      />
      <DiscoTextButton
        onClick={onRemoveQuestion}
        testid={`QuestionFormField.question-${questionOrder}.remove-button`}
        className={classes.removeButton}
      >
        <DiscoText variant={"body-sm"}>{"Remove"}</DiscoText>
      </DiscoTextButton>
    </div>
  )

  function handleEditorChange(v: string) {
    onContentChange(v)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
  },
  removeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.text.secondary,
  },
}))

export default observer(QuestionFormField)
