/**
 * @generated SignedSource<<acb889f301ab8536a3bc6b5c9bd80682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MemberGroupKind = "default" | "custom" | "role" | "%future added value";
export type MemberGroupRole = "owner" | "admin" | "manager" | "instructor" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditorMentionFragment$data = {
  readonly id: string;
  readonly user: {
    readonly id: string;
    readonly fullName: string;
  } | null;
  readonly memberGroup: {
    readonly id: string;
    readonly name: string;
    readonly kind: MemberGroupKind;
    readonly role: MemberGroupRole | null;
  } | null;
  readonly mentionedProduct: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly mentionedContentUsage: {
    readonly id: string;
    readonly content: {
      readonly id: string;
      readonly name: string | null;
    };
  } | null;
  readonly mentionedOccurrence: {
    readonly id: string;
    readonly name: string | null;
    readonly content: {
      readonly name: string | null;
    };
  } | null;
  readonly " $fragmentType": "EditorMentionFragment";
};
export type EditorMentionFragment$key = {
  readonly " $data"?: EditorMentionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditorMentionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditorMentionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroup",
      "kind": "LinkedField",
      "name": "memberGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "mentionedProduct",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsage",
      "kind": "LinkedField",
      "name": "mentionedContentUsage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Occurrence",
      "kind": "LinkedField",
      "name": "mentionedOccurrence",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentMention",
  "abstractKey": null
};
})();

(node as any).hash = "7db49d3d112aac2b2d65b2ec6efe6c30";

export default node;
