import FormStore from "@/core/form/store/FormStore"
import Form from "@components/form/Form"
import { DiscoButton, DiscoButtonProps } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps, Omit<DiscoButtonProps, "form"> {
  id?: string
  form: Pick<FormStore<any>, "isSubmitting" | "disabled">
  children: string
}

function FormSubmitButton(props: Props) {
  const { id, form, children, disabled, ...rest } = props
  const idFromContext = Form.useFormContext().id
  return (
    <DiscoButton
      disabled={disabled ?? form.disabled}
      shouldDisplaySpinner={form.isSubmitting}
      {...rest}
      form={id ?? idFromContext}
      type={"submit"}
    >
      {children}
    </DiscoButton>
  )
}

export default observer(FormSubmitButton)
