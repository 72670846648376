import AddRoundIcon from "@/core/ui/iconsax/linear/add.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoTooltip } from "@disco-ui"
import { IconButton } from "@material-ui/core"
import ButtonBase from "@material-ui/core/ButtonBase"
import classNames from "classnames"
import React, { useEffect, useState } from "react"

interface GlobalAddDropdownButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  classes?: { avatarContainer?: string }
  close?: boolean
}

function GlobalAddDropdownButton({
  onClick,
  close,
  classes: propsClasses,
  ...props
}: GlobalAddDropdownButtonProps) {
  const [isGlobalAddDropdownOpen, setIsGlobalAddDropdownOpen] = useState(false)
  const classes = useStyles({ isOpen: isGlobalAddDropdownOpen })
  useEffect(() => {
    setIsGlobalAddDropdownOpen(false)
  }, [close])
  return (
    <DiscoTooltip content={"Add New"} placement={"right"}>
      <ButtonBase className={classNames("fs-mask")} {...props}>
        <div className={classes.iconButtonContainer}>
          <div className={classNames(classes.iconButton)}>
            <IconButton
              onClick={handleClick}
              className={classNames(classes.iconButton, propsClasses)}
              size={"small"}
            >
              <AddRoundIcon width={20} height={20} />
            </IconButton>
          </div>
        </div>
      </ButtonBase>
    </DiscoTooltip>
  )

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onClick(e)
    setIsGlobalAddDropdownOpen(!isGlobalAddDropdownOpen)
    e.preventDefault()
    e.stopPropagation()
  }
}

type StyleProps = {
  isOpen: boolean
}
const useStyles = makeUseStyles((theme) => ({
  iconButton: ({ isOpen }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      width: "44px",
      height: "44px",
    },
    "& svg": {
      "& path": {
        color: theme.palette.primary.contrastText,
      },
      transform: isOpen ? "rotate(45deg)" : null,
    },
  }),
  iconButtonContainer: {
    width: "44px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export default GlobalAddDropdownButton
