import { LocationState } from "@/core/route/util/routeUtils"
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop: React.FC = ({ children }) => {
  const { pathname, hash } = useLocation<LocationState>()
  useEffect(() => {
    // if we are trying to scroll to an element on the page using location.hash, don't scroll to top
    if (hash) return
    window.scrollTo(0, 0)
  }, [pathname, hash])

  return <>{children}</>
}

export default ScrollToTop
