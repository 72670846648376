import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $createParagraphNode, $isParagraphNode, RootNode } from "lexical"
import { useEffect } from "react"

/* This plugin ensures the editor always ends in a paragraph node */
export default function PreserveParagraphPlugin(): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (node) => {
      const lastChild = node.getLastChild()

      if ($isParagraphNode(lastChild)) return

      const paragraph = $createParagraphNode()

      node.append(paragraph)
    })
  }, [editor])

  return null
}
