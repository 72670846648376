import makeUseStyles from "@assets/style/util/makeUseStyles"
import useResizeObserver from "@utils/hook/useResizeObserver"
import classNames from "classnames"
import { useRef } from "react"

export interface DiscoResizableContainerProps {
  children: React.ReactNode
  initialHeight?: string
  width?: string
  /** This gets triggered onMouseUp after resizing */
  onResizeEnd: (height: string) => void
  disableResize?: boolean
  className?: string
}

function DiscoResizableContainer({
  children,
  initialHeight,
  width,
  onResizeEnd,
  disableResize,
  className,
}: DiscoResizableContainerProps) {
  const isResizingRef = useRef<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useResizeObserver(containerRef, () => {
    isResizingRef.current = true
  })

  const classes = useStyles({ initialHeight, width, disableResize })
  return (
    <span
      ref={containerRef}
      className={classNames(classes.container, className)}
      onMouseUp={handleContainerMouseUp}
      role={"button"}
      tabIndex={-1}
    >
      {children}
    </span>
  )

  function handleContainerMouseUp() {
    if (isResizingRef.current) {
      isResizingRef.current = false

      if (!containerRef.current) return
      onResizeEnd(`${containerRef.current.clientHeight}px`)
    }
  }
}

type StyleProps = {
  initialHeight?: string
  width?: string
  disableResize?: boolean
}

const useStyles = makeUseStyles({
  container: {
    height: ({ initialHeight }: StyleProps) => initialHeight,
    width: ({ width }: StyleProps) => width,
    position: "relative",
    resize: ({ disableResize }: StyleProps) => (disableResize ? undefined : "vertical"),
    overflow: "auto",
    display: "inline-block",
  },
})

export default DiscoResizableContainer
