import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getSelection } from "lexical"
import { useCallback, useEffect } from "react"

interface UseEditorElementAnchorProps {
  editorAnchorElem?: HTMLElement
  elementAnchorRef: React.MutableRefObject<HTMLDivElement | null>
  opts?: { verticalGap?: number; horizontalOffset?: number }
}
export function useEditorElementAnchor({
  editorAnchorElem,
  elementAnchorRef,
  opts,
}: UseEditorElementAnchorProps) {
  const [editor] = useLexicalComposerContext()

  const updatePlaceholderPosition = useCallback(() => {
    const selection = $getSelection()

    const cursorAnchorElem = elementAnchorRef.current
    const nativeSelection = window.getSelection()

    if (cursorAnchorElem === null || !editorAnchorElem) {
      return
    }

    if (
      selection !== null &&
      nativeSelection !== null &&
      nativeSelection.focusNode &&
      (nativeSelection.focusNode as any).getBoundingClientRect !== undefined
    ) {
      const rect = (nativeSelection.focusNode as any).getBoundingClientRect()
      LexicalUtils.setFloatingElemPosition(rect, cursorAnchorElem, editorAnchorElem, opts)
    }
  }, [elementAnchorRef, editorAnchorElem, opts])

  useEffect(() => {
    const scrollerElem = editorAnchorElem?.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        updatePlaceholderPosition()
      })
    }

    window.addEventListener("resize", update)
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [editor, updatePlaceholderPosition, editorAnchorElem])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updatePlaceholderPosition()
    })
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updatePlaceholderPosition()
      })
    })
  }, [editor, updatePlaceholderPosition])
}
