import { BaseEmoji, Emoji } from "emoji-mart"

type ContentEmojiProps = {
  emoji: BaseEmoji
  size: number
}

function ContentEmoji({ emoji, size }: ContentEmojiProps) {
  return <Emoji emoji={emoji} skin={emoji.skin || undefined} size={size} />
}

export default ContentEmoji
