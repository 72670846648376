import { MemberOnboardingConstants } from "@/member-onboarding/utils/memberOnboardingConstants"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal } from "@disco-ui"
import DiscoSimpleStepper, {
  DiscoSimpleStepperProps,
} from "@disco-ui/stepper/DiscoSimpleStepper"
import { TestIDProps } from "@utils/typeUtils"

export interface FullPageModalStepperProps extends TestIDProps {
  isOpen: boolean
  children: React.ReactNode
  steps: DiscoSimpleStepperProps["steps"]
  hideStepper?: boolean
  onClose?: VoidFunction
}

function FullPageModalStepper({
  steps,
  children,
  isOpen,
  testid = "FullPageModalStepper",
  hideStepper = false,
  onClose,
}: FullPageModalStepperProps) {
  const classes = useStyles()
  return (
    <DiscoModal
      testid={testid}
      isOpen={isOpen}
      title={!hideStepper && <DiscoSimpleStepper testid={testid} steps={steps} />}
      onClose={onClose}
      hideCloseIcon={!onClose}
      modalContentLabel={"Member Onboarding Modal"}
      classes={{
        container: classes.container,
        wrapper: classes.wrapper,
        wrapperChild: classes.wrapperChild,
        headerRow: classes.headerRow,
        body: classes.body,
        closeButton: classes.closeButton,
      }}
      body={children}
    />
  )
}
const useStyles = makeUseStyles((theme) => ({
  container: {
    width: `calc(100% - ${theme.spacing(4)}px)`,
    height: `calc(100% - ${theme.spacing(4)}px)`,
    maxHeight: "unset",
    [theme.breakpoints.down("sm")]: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
      height: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
  headerRow: {
    marginBottom: 0,
    marginTop: MemberOnboardingConstants.MARGIN_TOP,
    justifyContent: "center",
  },
  wrapper: {
    padding: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  wrapperChild: {
    display: "grid",
    height: "100%",
    gridTemplateRows: "auto 1fr",
    alignItems: "center",
    rowGap: theme.spacing(1),
  },
  body: {
    height: "100%",
    overflow: "hidden",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

export default FullPageModalStepper
