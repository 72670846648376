// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/nodes/MentionNode.ts
import { GlobalID } from "@/relay/RelayTypes"
import EditorMention from "@components/editor/plugins/mentions/EditorMention"
import {
  $applyNodeReplacement,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical"

export type SerializedMentionNode = Spread<
  {
    mentionId: GlobalID
    mentionableId: GlobalID
  },
  SerializedLexicalNode
>
export class MentionNode extends DecoratorNode<JSX.Element> {
  __mentionId: string
  __mentionableId: GlobalID

  static getType(): string {
    return "mention"
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mentionId, node.__mentionableId, node.__key)
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(
      serializedNode.mentionId,
      serializedNode.mentionableId
    )
    return node
  }

  constructor(mentionId: GlobalID, mentionableId: GlobalID, key?: NodeKey) {
    super(key)
    this.__mentionId = mentionId
    this.__mentionableId = mentionableId
  }

  exportJSON(): SerializedMentionNode {
    return {
      mentionId: this.__mentionId,
      mentionableId: this.__mentionableId,
      type: "mention",
      version: 1,
    }
  }

  createDOM() {
    const element = document.createElement("span")
    element.setAttribute("data-disco-mention-id", this.__mentionId)
    element.setAttribute("data-disco-mentionable-id", this.__mentionableId)
    return element
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span")
    element.setAttribute("data-disco-mention-id", this.__mentionId)
    element.setAttribute("data-disco-mentionable-id", this.__mentionableId)
    return { element }
  }

  updateDOM(): false {
    return false
  }

  decorate(): JSX.Element {
    return (
      <EditorMention
        nodeKey={this.getKey()}
        mentionId={this.__mentionId}
        mentionableId={this.__mentionableId}
      />
    )
  }
}

export function $createMentionNode(
  mentionId: GlobalID,
  mentionableId: GlobalID
): MentionNode {
  const mentionNode = new MentionNode(mentionId, mentionableId)
  return $applyNodeReplacement(mentionNode)
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode {
  return node instanceof MentionNode
}
