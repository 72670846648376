import PaidFeatureTooltip from "@/organization/entitlements/PaidFeatureTooltip"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { observer } from "mobx-react-lite"
import { createElement, forwardRef } from "react"

interface WithEntitlementProps {
  entitlement?: Entitlement
  hideTooltip?: boolean
  children: React.FC<{
    hasEntitlement: boolean
    ref: any
  }>
}

const WithEntitlement = forwardRef<HTMLElement, WithEntitlementProps>(function (
  { entitlement, hideTooltip, children },
  ref
) {
  const hasEntitlementValue = useHasEntitlement(entitlement)
  const hasEntitlement = entitlement ? hasEntitlementValue : true // default to true

  const element = createElement(observer(children), {
    ref,
    hasEntitlement,
  })

  if (!hasEntitlement && !hideTooltip)
    return <PaidFeatureTooltip entitlement={entitlement}>{element}</PaidFeatureTooltip>

  return element
})

export default WithEntitlement
