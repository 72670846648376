import EditorImageUploadFile from "@components/editor/plugins/image/EditorImageUploadFile"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical"

export interface ImageUploadFilePayload {
  key?: NodeKey
  file: File
}

export type SerializedImageUploadFileNode = Spread<
  {
    file: File
  },
  SerializedDecoratorBlockNode
>

export class ImageUploadFileNode extends DecoratorBlockNode {
  __file: File

  static getType(): string {
    return "image-upload-file"
  }

  static clone(node: ImageUploadFileNode): ImageUploadFileNode {
    return new ImageUploadFileNode(node.__file, node.__key)
  }

  static importJSON(serializedNode: SerializedImageUploadFileNode): ImageUploadFileNode {
    const { file } = serializedNode
    const node = $createImageUploadFileNode({ file })
    return node
  }

  constructor(file: File, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__file = file
  }

  exportJSON(): SerializedImageUploadFileNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "image-upload-file",
      file: this.getFile(),
    }
  }

  // View
  updateDOM(): false {
    return false
  }

  getFile(): File {
    return this.__file
  }

  decorate(): JSX.Element {
    return <EditorImageUploadFile nodeKey={this.getKey()} file={this.__file} />
  }
}

export function $createImageUploadFileNode({
  file,
  key,
}: ImageUploadFilePayload): ImageUploadFileNode {
  return new ImageUploadFileNode(file, key)
}

export function $isImageUploadFileNode(
  node: LexicalNode | null | undefined
): node is ImageUploadFileNode {
  return node instanceof ImageUploadFileNode
}
