import Relay from "@/relay/relayUtils"
import { EditorMentionData } from "@components/editor/plugins/mentions/EditorMention"
import { $createMentionNode } from "@components/editor/plugins/mentions/MentionNode"
import { EditorMentionsProviderFragment$key } from "@components/editor/plugins/mentions/__generated__/EditorMentionsProviderFragment.graphql"
import { InitialEditorStateType } from "@lexical/react/LexicalComposer"
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical"
import React, { useContext, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type EditorMentionsContextValue = {
  mentions: EditorMentionData[]
  setMentions: React.Dispatch<React.SetStateAction<EditorMentionData[]>>
}

const EditorMentionsContext = React.createContext<EditorMentionsContextValue>({
  mentions: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMentions: () => {},
})

export interface EditorMentionsProviderProps {
  children: React.ReactNode
  contentKey?: EditorMentionsProviderFragment$key | null
  defaultMentions?: EditorMentionData[]
}

function EditorMentionsProvider(props: EditorMentionsProviderProps) {
  // Read existing mentions into the editor state.
  const content = useFragment<EditorMentionsProviderFragment$key>(
    graphql`
      fragment EditorMentionsProviderFragment on Content {
        id
        mentions {
          __id
          edges {
            node {
              id
              ...EditorMentionFragment @relay(mask: false)
            }
          }
        }
      }
    `,
    props.contentKey ?? null
  )

  const [mentions, setMentions] = useState<EditorMentionData[]>([
    ...Relay.connectionToArray(content?.mentions),
    ...(props?.defaultMentions || []),
  ])

  return (
    <EditorMentionsContext.Provider value={{ mentions, setMentions }}>
      {props.children}
    </EditorMentionsContext.Provider>
  )
}

// If default mentions are provided, create the initial editor state from them.
export function getInitialStateFromMentions(
  mentions: EditorMentionData[]
): InitialEditorStateType {
  return () => {
    const root = $getRoot()
    const paragraph = $createParagraphNode()

    for (const mention of mentions) {
      const mentionNode = $createMentionNode(
        mention.id,
        mention.user?.id ||
          mention.memberGroup?.id ||
          mention.mentionedProduct?.id ||
          mention.mentionedOccurrence?.id ||
          mention.mentionedContentUsage?.id ||
          ""
      )
      paragraph.append(mentionNode)
      const text = $createTextNode(" ")
      paragraph.append(text)
    }
    root.append(paragraph)
  }
}

export function useEditorMentions() {
  const context = useContext(EditorMentionsContext)
  if (!context) {
    throw new Error("useEditorMentions must be used within a EditorMentionsProvider")
  }
  return context
}

export default EditorMentionsProvider
