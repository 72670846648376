// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/nodes/ImageNode.tsx
import EditorImage from "@components/editor/plugins/image/EditorImage"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  ElementFormatType,
  LexicalNode,
  NodeKey,
  Spread,
} from "lexical"

export interface ImagePayload {
  key?: NodeKey
  src: string
  altText: string
  alignment?: ElementFormatType
  widthPercentage?: number
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, style } = domNode

    const widthPercentage = style.width ? Number(style.width.replace("%", "")) : undefined

    const node = $createImageNode({ src, altText, widthPercentage })
    return { node }
  }
  return null
}

export type SerializedImageNode = Spread<
  {
    src: string
    altText: string
    alignment?: ElementFormatType
    widthPercentage?: number
  },
  SerializedDecoratorBlockNode
>

export class ImageNode extends DecoratorBlockNode {
  __src: string
  __altText: string
  __alignment?: ElementFormatType
  __widthPercentage?: number

  static getType(): string {
    return "image"
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      {
        src: node.__src,
        altText: node.__altText,
        alignment: node.__alignment,
        widthPercentage: node.__widthPercentage,
      },
      node.__key,
      node.__format
    )
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { alignment, altText, widthPercentage, src } = serializedNode
    const node = $createImageNode({ alignment, altText, widthPercentage, src })
    return node
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img")
    element.setAttribute("src", this.__src)
    element.setAttribute("alt", this.__altText)
    element.setAttribute("style", `width: ${this.__widthPercentage ?? 100}%`)
    return { element }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    }
  }

  constructor(image: ImagePayload, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__src = image.src
    this.__altText = image.altText
    this.__alignment = image.alignment
    this.__widthPercentage = image.widthPercentage
  }

  exportJSON(): SerializedImageNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "image",
      src: this.getSrc(),
      altText: this.getAltText(),
      alignment: this.getAlignment(),
      widthPercentage: this.getWidthPercentage(),
    }
  }

  setAlignment(alignment: ElementFormatType): void {
    const writable = this.getWritable()
    writable.__alignment = alignment
  }

  setWidthPercentage(widthPercentage: number): void {
    const writable = this.getWritable()
    writable.__widthPercentage = widthPercentage
  }

  // View
  updateDOM(): false {
    return false
  }

  getSrc(): string {
    return this.__src
  }

  getAltText(): string {
    return this.__altText
  }

  getAlignment(): ElementFormatType | undefined {
    return this.__alignment
  }

  getWidthPercentage(): number | undefined {
    return this.__widthPercentage
  }

  decorate(): JSX.Element {
    return (
      <EditorImage
        nodeKey={this.getKey()}
        src={this.__src}
        altText={this.__altText}
        alignment={this.__alignment}
        widthPercentage={this.__widthPercentage}
      />
    )
  }
}

export function $createImageNode({
  src,
  altText,
  alignment,
  widthPercentage,
  key,
}: ImagePayload): ImageNode {
  return new ImageNode({ src, altText, alignment, widthPercentage }, key)
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode
}
