import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { useEditorElementAnchor } from "@components/editor/utils/useEditorElementAnchor"
import { DiscoText } from "@disco-ui"
import { LexicalEditor } from "lexical"
import { useRef } from "react"

function TextPlaceholder({
  editor,
  anchorElem,
  placeholder,
}: {
  editor: LexicalEditor
  anchorElem: HTMLElement
  placeholder: string
}): JSX.Element {
  const { isBlockOptionsOpen } = useLexicalEditorContext()

  const placeholderAnchorRef = useRef<HTMLDivElement | null>(null)

  useEditorElementAnchor({
    editorAnchorElem: anchorElem,
    elementAnchorRef: placeholderAnchorRef,
    opts: { verticalGap: -31.5, horizontalOffset: 0 },
  })

  const classes = useStyles()

  return (
    <div ref={placeholderAnchorRef} className={classes.placeholder}>
      {editor.isEditable() && !isBlockOptionsOpen && (
        <DiscoText color={"groovy.neutral.400"} className={classes.text}>
          {placeholder}
        </DiscoText>
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  placeholder: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    transition: "opacity 0.5s",
    willChange: "transform",
    pointerEvents: "none",
  },
  text: {
    padding: theme.spacing(0.5, 0),
  },
}))

export default TextPlaceholder
