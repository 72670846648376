// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/TableActionMenuPlugin/index.tsx
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoIcon, DiscoIconButton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import useLexicalEditable from "@lexical/react/useLexicalEditable"
import {
  $deleteTableColumn__EXPERIMENTAL as $deleteTableColumn,
  $deleteTableRow__EXPERIMENTAL as $deleteTableRow,
  $getTableCellNodeFromLexicalNode,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn__EXPERIMENTAL as $insertTableColumn,
  $insertTableRow__EXPERIMENTAL as $insertTableRow,
  $isTableCellNode,
  $isTableRowNode,
  $unmergeCell,
  HTMLTableElementWithWithTableSelectionState,
  TableCellHeaderStates,
  TableCellNode,
  getTableSelectionFromTableElement,
} from "@lexical/table"
import { Popover } from "@material-ui/core"
import {
  $createParagraphNode,
  DEPRECATED_$getNodeTriplet as $getNodeTriplet,
  $getRoot,
  $getSelection,
  $isElementNode,
  DEPRECATED_$isGridCellNode as $isGridCellNode,
  DEPRECATED_$isGridSelection as $isGridSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  DEPRECATED_GridCellNode,
  ElementNode,
  DEPRECATED_GridCellNode as GridCellNode,
  GridSelection,
} from "lexical"
import pluralize from "pluralize"
import { ReactPortal, useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

function computeSelectionCount(selection: GridSelection): {
  columns: number
  rows: number
} {
  const selectionShape = selection.getShape()
  return {
    columns: selectionShape.toX - selectionShape.fromX + 1,
    rows: selectionShape.toY - selectionShape.fromY + 1,
  }
}

// This is important when merging cells as there is no good way to re-merge weird shapes (a result
// of selecting merged cells and non-merged)
function isGridSelectionRectangular(selection: GridSelection): boolean {
  const nodes = selection.getNodes()
  const currentRows: Array<number> = []
  let currentRow = null
  let expectedColumns = null
  let currentColumns = 0
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if ($isTableCellNode(node)) {
      const row = node.getParentOrThrow()
      if (!$isTableRowNode(row)) {
        throw new Error("Expected CellNode to have a RowNode parent")
      }
      if (currentRow !== row) {
        if (expectedColumns !== null && currentColumns !== expectedColumns) {
          return false
        }
        if (currentRow !== null) {
          expectedColumns = currentColumns
        }
        currentRow = row
        currentColumns = 0
      }
      const colSpan = node.__colSpan
      for (let j = 0; j < colSpan; j++) {
        if (currentRows[currentColumns + j] === undefined) {
          currentRows[currentColumns + j] = 0
        }
        currentRows[currentColumns + j] += node.__rowSpan
      }
      currentColumns += colSpan
    }
  }
  return (
    (expectedColumns === null || currentColumns === expectedColumns) &&
    currentRows.every((v) => v === currentRows[0])
  )
}

function $canUnmerge(): boolean {
  const selection = $getSelection()
  if (
    ($isRangeSelection(selection) && !selection.isCollapsed()) ||
    ($isGridSelection(selection) && !selection.anchor.is(selection.focus)) ||
    (!$isRangeSelection(selection) && !$isGridSelection(selection))
  ) {
    return false
  }
  const [cell] = $getNodeTriplet(selection.anchor)
  return cell.__colSpan > 1 || cell.__rowSpan > 1
}

function $cellContainsEmptyParagraph(cell: GridCellNode): boolean {
  if (cell.getChildrenSize() !== 1) {
    return false
  }
  const firstChild = cell.getFirstChildOrThrow()
  if (!$isParagraphNode(firstChild) || !firstChild.isEmpty()) {
    return false
  }
  return true
}

function $selectLastDescendant(node: ElementNode): void {
  const lastDescendant = node.getLastDescendant()
  if ($isTextNode(lastDescendant)) {
    lastDescendant.select()
  } else if ($isElementNode(lastDescendant)) {
    lastDescendant.selectEnd()
  } else if (lastDescendant !== null) {
    lastDescendant.selectNext()
  }
}

type TableCellActionItemsProps = Readonly<{
  onClose: () => void
  tableCellNode: TableCellNode
}>

function TableCellActionItems({
  onClose,
  tableCellNode: _tableCellNode,
}: TableCellActionItemsProps) {
  const [editor] = useLexicalComposerContext()
  const [tableCellNode, updateTableCellNode] = useState(_tableCellNode)
  const [selectionCounts, updateSelectionCounts] = useState({
    columns: 1,
    rows: 1,
  })
  const [canMergeCells, setCanMergeCells] = useState(false)
  const [canUnmergeCell, setCanUnmergeCell] = useState(false)

  useEffect(() => {
    return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
      const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === "updated"

      if (nodeUpdated) {
        editor.getEditorState().read(() => {
          updateTableCellNode(tableCellNode.getLatest())
        })
      }
    })
  }, [editor, tableCellNode])

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      // Merge cells
      if ($isGridSelection(selection)) {
        const currentSelectionCounts = computeSelectionCount(selection)
        updateSelectionCounts(computeSelectionCount(selection))
        setCanMergeCells(
          isGridSelectionRectangular(selection) &&
            (currentSelectionCounts.columns > 1 || currentSelectionCounts.rows > 1)
        )
      }
      // Unmerge cell
      setCanUnmergeCell($canUnmerge())
    })
  }, [editor])

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
        const tableElement = editor.getElementByKey(
          tableNode.getKey()
        ) as HTMLTableElementWithWithTableSelectionState

        if (!tableElement) {
          throw new Error("Expected to find tableElement in DOM")
        }

        const tableSelection = getTableSelectionFromTableElement(tableElement)
        if (tableSelection !== null) {
          tableSelection.clearHighlight()
        }

        tableNode.markDirty()
        updateTableCellNode(tableCellNode.getLatest())
      }

      const rootNode = $getRoot()
      rootNode.selectStart()
    })
  }, [editor, tableCellNode])

  const mergeTableCellsAtSelection = () => {
    editor.update(() => {
      const selection = $getSelection()

      if (!$isGridSelection(selection)) return

      const { columns, rows } = computeSelectionCount(selection)
      const nodes = selection.getNodes()

      let firstCell: null | DEPRECATED_GridCellNode = null

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i]
        if ($isGridCellNode(node)) {
          if (firstCell === null) {
            node.setColSpan(columns).setRowSpan(rows)
            firstCell = node
            const isEmpty = $cellContainsEmptyParagraph(node)
            let firstChild
            if (isEmpty && $isParagraphNode((firstChild = node.getFirstChild()))) {
              firstChild.remove()
            }
          } else if ($isGridCellNode(firstCell)) {
            const isEmpty = $cellContainsEmptyParagraph(node)
            if (!isEmpty) {
              firstCell.append(...node.getChildren())
            }
            node.remove()
          }
        }
      }
      if (firstCell !== null) {
        if (firstCell.getChildrenSize() === 0) {
          firstCell.append($createParagraphNode())
        }
        $selectLastDescendant(firstCell)
      }
      onClose()
    })
  }

  const unmergeTableCellsAtSelection = () => {
    editor.update(() => {
      $unmergeCell()
    })
  }

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        $insertTableRow(shouldInsertAfter)
        onClose()
      })
    },
    [editor, onClose]
  )

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        for (let i = 0; i < selectionCounts.columns; i++) {
          $insertTableColumn(shouldInsertAfter)
        }
        onClose()
      })
    },
    [editor, onClose, selectionCounts.columns]
  )

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableRow()
      onClose()
    })
  }, [editor, onClose])

  const deleteTableAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
      tableNode.remove()

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableColumn()
      onClose()
    })
  }, [editor, onClose])

  const toggleTableRowIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode)

      const tableRows = tableNode.getChildren()

      if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
        throw new Error("Expected table cell to be inside of table row.")
      }

      const tableRow = tableRows[tableRowIndex]

      if (!$isTableRowNode(tableRow)) {
        throw new Error("Expected table row")
      }

      tableRow.getChildren().forEach((tableCell) => {
        if (!$isTableCellNode(tableCell)) {
          throw new Error("Expected table cell")
        }

        tableCell.toggleHeaderStyle(TableCellHeaderStates.ROW)
      })

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const toggleTableColumnIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode)

      const tableRows = tableNode.getChildren()

      for (let r = 0; r < tableRows.length; r++) {
        const tableRow = tableRows[r]

        if (!$isTableRowNode(tableRow)) {
          throw new Error("Expected table row")
        }

        const tableCells = tableRow.getChildren()

        if (tableColumnIndex >= tableCells.length || tableColumnIndex < 0) {
          throw new Error("Expected table cell to be inside of table row.")
        }

        const tableCell = tableCells[tableColumnIndex]

        if (!$isTableCellNode(tableCell)) {
          throw new Error("Expected table cell")
        }

        tableCell.toggleHeaderStyle(TableCellHeaderStates.COLUMN)
      }

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  return (
    <ul role={"menu"}>
      {renderMergeCellsButton()}
      <DiscoDropdownItem
        title={`Insert ${pluralize("row", selectionCounts.rows, true)} above`}
        onClick={() => insertTableRowAtSelection(false)}
      />
      <DiscoDropdownItem
        title={`Insert ${pluralize("row", selectionCounts.rows, true)} below`}
        onClick={() => insertTableRowAtSelection(true)}
      />
      <DiscoDivider />
      <DiscoDropdownItem
        title={`Insert ${pluralize("column", selectionCounts.columns, true)} left`}
        onClick={() => insertTableColumnAtSelection(false)}
      />
      <DiscoDropdownItem
        onClick={() => insertTableColumnAtSelection(true)}
        title={`Insert ${pluralize("column", selectionCounts.columns, true)} right`}
      />
      <DiscoDivider />
      <DiscoDropdownItem
        onClick={() => deleteTableColumnAtSelection()}
        title={"Delete column"}
      />
      <DiscoDropdownItem
        onClick={() => deleteTableRowAtSelection()}
        title={"Delete row"}
      />
      <DiscoDropdownItem
        onClick={() => deleteTableAtSelection()}
        title={"Delete table"}
      />
      <DiscoDivider />
      <DiscoDropdownItem
        onClick={() => toggleTableRowIsHeader()}
        title={`${
          (tableCellNode.__headerState && TableCellHeaderStates.ROW) ===
          TableCellHeaderStates.ROW
            ? "Remove"
            : "Add"
        } row header`}
      />
      <DiscoDropdownItem
        onClick={() => toggleTableColumnIsHeader()}
        title={`${
          (tableCellNode.__headerState && TableCellHeaderStates.COLUMN) ===
          TableCellHeaderStates.COLUMN
            ? "Remove"
            : "Add"
        } column header`}
      />
    </ul>
  )

  function renderMergeCellsButton() {
    if (canMergeCells) {
      return (
        <>
          <DiscoDropdownItem
            onClick={() => mergeTableCellsAtSelection()}
            title={"Merge cells"}
          />
          <DiscoDivider />
        </>
      )
    }

    if (canUnmergeCell) {
      return (
        <>
          <DiscoDropdownItem
            onClick={() => unmergeTableCellsAtSelection()}
            title={"Unmerge cells"}
          />
          <DiscoDivider />
        </>
      )
    }

    return null
  }
}

function TableCellActionMenuContainer({
  anchorElem,
}: {
  anchorElem: HTMLElement
}): JSX.Element {
  const [editor] = useLexicalComposerContext()

  const menuButtonRef = useRef(null)
  const menuRootRef = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [tableCellNode, setTableMenuCellNode] = useState<TableCellNode | null>(null)

  const moveMenu = useCallback(() => {
    const menu = menuButtonRef.current
    const selection = $getSelection()
    const nativeSelection = window.getSelection()
    const { activeElement } = document

    if (selection == null || menu == null) {
      setTableMenuCellNode(null)
      return
    }

    const rootElement = editor.getRootElement()

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode()
      )

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null)
        return
      }

      const tableCellParentNodeDOM = editor.getElementByKey(
        tableCellNodeFromSelection.getKey()
      )

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null)
        return
      }

      setTableMenuCellNode(tableCellNodeFromSelection)
    } else if (!activeElement) {
      setTableMenuCellNode(null)
    }
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        moveMenu()
      })
    })
  })

  useEffect(() => {
    const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null

    if (menuButtonDOM !== null && tableCellNode !== null) {
      const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey())

      if (tableCellNodeDOM === null) {
        menuButtonDOM.style.opacity = "0"
        menuButtonDOM.style.transform = "translate(-10000px, -10000px)"
      } else {
        const tableCellRect = tableCellNodeDOM.getBoundingClientRect()
        const menuRect = menuButtonDOM.getBoundingClientRect()
        const anchorRect = anchorElem.getBoundingClientRect()

        const top = tableCellRect.top - anchorRect.top + 4
        const left = tableCellRect.right - menuRect.width - 10 - anchorRect.left

        menuButtonDOM.style.opacity = "1"
        menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`
      }
    }
  }, [menuButtonRef, tableCellNode, editor, anchorElem])

  const prevTableCellDOM = useRef(tableCellNode)

  useEffect(() => {
    if (prevTableCellDOM.current !== tableCellNode) {
      setIsMenuOpen(false)
    }

    prevTableCellDOM.current = tableCellNode
  }, [prevTableCellDOM, tableCellNode])

  const classes = useStyles()

  return (
    <div ref={menuButtonRef} className={classes.tableCellActionButtonContainer}>
      {tableCellNode !== null && (
        <>
          <DiscoIconButton
            ref={menuRootRef}
            onClick={(e) => {
              e.stopPropagation()
              setIsMenuOpen(!isMenuOpen)
            }}
            width={16}
            height={16}
            variant={"outlined"}
            className={classes.tableCellActionButton}
            svgStyles={{ width: 18, height: 18 }}
          >
            <DiscoIcon icon={"chevron"} />
          </DiscoIconButton>

          {menuRootRef.current && (
            <Popover
              open={isMenuOpen}
              classes={{ paper: classes.popover }}
              anchorEl={menuRootRef.current}
              onClose={() => setIsMenuOpen(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              onClick={() => setIsMenuOpen(false)}
            >
              <TableCellActionItems
                onClose={() => setIsMenuOpen(false)}
                tableCellNode={tableCellNode}
              />
            </Popover>
          )}
        </>
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  tableCellActionButtonContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    willChange: "transform",
  },
  tableCellActionButton: {
    transform: "rotate(180deg)",
    borderRadius: theme.measure.borderRadius.default,
    background: theme.palette.background.paper,

    "& span": {
      width: "unset",
    },
  },
  popover: {
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    minWidth: "180px",
  },
}))

export default function TableActionMenuPlugin({
  anchorElem = document.body,
}: {
  anchorElem?: HTMLElement
}): null | ReactPortal {
  const isEditable = useLexicalEditable()
  return createPortal(
    isEditable ? <TableCellActionMenuContainer anchorElem={anchorElem} /> : null,
    anchorElem
  )
}
