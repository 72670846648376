import EditorVideoUploadFile from "@components/editor/plugins/video/EditorVideoUploadFile"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical"

export interface VideoUploadFilePayload {
  key?: NodeKey
  file: File
}

export type SerializedVideoUploadFileNode = Spread<
  {
    file: File
  },
  SerializedDecoratorBlockNode
>

export class VideoUploadFileNode extends DecoratorBlockNode {
  __file: File

  static getType(): string {
    return "video-upload-file"
  }

  static clone(node: VideoUploadFileNode): VideoUploadFileNode {
    return new VideoUploadFileNode(node.__file, node.__key)
  }

  static importJSON(serializedNode: SerializedVideoUploadFileNode): VideoUploadFileNode {
    const { file } = serializedNode
    const node = $createVideoUploadFileNode({ file })
    return node
  }

  constructor(file: File, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__file = file
  }

  exportJSON(): SerializedVideoUploadFileNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "video-upload-file",
      file: this.getFile(),
    }
  }

  // View
  updateDOM(): false {
    return false
  }

  getFile(): File {
    return this.__file
  }

  decorate(): JSX.Element {
    return <EditorVideoUploadFile nodeKey={this.getKey()} file={this.__file} />
  }
}

export function $createVideoUploadFileNode({
  file,
  key,
}: VideoUploadFilePayload): VideoUploadFileNode {
  return new VideoUploadFileNode(file, key)
}

export function $isVideoUploadFileNode(
  node: LexicalNode | null | undefined
): node is VideoUploadFileNode {
  return node instanceof VideoUploadFileNode
}
