import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ContentEmojiButton from "@components/reactions/ContentEmojiButton"
import { ContentUniqueReactionsListContentQuery } from "@components/reactions/__generated__/ContentUniqueReactionsListContentQuery.graphql"
import { ContentUniqueReactionsListContentUsageQuery } from "@components/reactions/__generated__/ContentUniqueReactionsListContentUsageQuery.graphql"
import { DiscoButton, DiscoButtonSkeleton, DiscoText, DiscoTooltip } from "@disco-ui"
import useResizeObserver from "@utils/hook/useResizeObserver"
import React, { useEffect, useRef, useState } from "react"
import { useSubscribeToInvalidationState } from "react-relay"
import { graphql } from "relay-runtime"

type ContentReactionButtonProps = {
  contentId: GlobalID
  contentUsageId?: GlobalID
}

function ContentUniqueReactionsList(props: ContentReactionButtonProps) {
  const { contentId, contentUsageId } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const [moreReactions, setMoreReactions] = useState(0)

  const emojiContainerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState<number>(
    emojiContainerRef.current?.clientWidth || 0
  )
  const [buttonCount, setButtonCount] = useState(0)
  const buttonWidth = 55 // width of emoji button and gap

  useResizeObserver(emojiContainerRef, (el) => setContainerWidth(el.clientWidth))

  const [{ content }, refetchContent] =
    Relay.useRefetchableQuery<ContentUniqueReactionsListContentQuery>(
      graphql`
        query ContentUniqueReactionsListContentQuery($id: ID!) {
          content: node(id: $id) {
            ... on Content {
              id
              uniqueReactions {
                __id
                edges {
                  node {
                    id
                    ...ContentEmojiButton_ContentReactionFragment
                  }
                }
              }
            }
          }
        }
      `,
      {
        id: contentId,
      },
      { fetchPolicy: "store-and-network" }
    )

  const [{ contentUsage }, refetchContentUsage] =
    Relay.useRefetchableQuery<ContentUniqueReactionsListContentUsageQuery>(
      graphql`
        query ContentUniqueReactionsListContentUsageQuery($id: ID!) {
          contentUsage: node(id: $id) {
            ... on ContentUsage {
              id
              contentId
              uniqueReactions {
                __id
                edges {
                  node {
                    id
                    ...ContentEmojiButton_ContentReactionFragment
                  }
                }
              }
            }
          }
        }
      `,
      {
        id: contentUsageId || "",
      },
      { fetchPolicy: "network-only" }
    )

  const uniqueReactions = Relay.connectionToArray(
    contentUsage?.uniqueReactions || content?.uniqueReactions
  )
  const classes = useStyles()

  useEffect(() => {
    if (uniqueReactions) {
      setButtonCount(Math.max(Math.floor(Number(containerWidth) / buttonWidth) - 1, 0))
      setMoreReactions(uniqueReactions.length - buttonCount)
    }
  }, [containerWidth, uniqueReactions, buttonCount])

  useSubscribeToInvalidationState(
    [
      ...(content?.uniqueReactions ? [content.uniqueReactions.__id] : []),
      ...(contentUsage?.uniqueReactions ? [contentUsage.uniqueReactions.__id] : []),
    ],
    () => {
      if (content) {
        refetchContent({ id: contentId })
      }
      if (contentUsage && contentUsageId) {
        refetchContentUsage({ id: contentUsageId })
      }
    }
  )

  if (!uniqueReactions.length) return null

  const displayedReactions = isExpanded
    ? uniqueReactions
    : uniqueReactions.slice(0, buttonCount)

  return (
    <div ref={emojiContainerRef} className={classes.reactionsRow}>
      {displayedReactions.map((reactionNode) => {
        if (!reactionNode) return null
        if (!content && !contentUsage) return <div key={reactionNode.id} />

        return (
          <ContentEmojiButton
            key={reactionNode.id}
            contentKey={reactionNode}
            contentId={contentUsage?.contentId || content?.id || contentId}
            contentUsageId={contentUsageId}
            size={"xsmall"}
          />
        )
      })}
      {!isExpanded && moreReactions > 0 && (
        <DiscoTooltip
          content={
            <div className={classes.innerTooltip}>
              <DiscoText variant={"body-sm"} color={"common.white"}>
                {"Show more reactions"}
              </DiscoText>
            </div>
          }
        >
          <DiscoButton
            onClick={toggleExpandedView}
            classes={{ root: classes.button }}
            color={"transparent"}
            size={"xsmall"}
          >
            {moreReactions > 0 && (
              <DiscoText className={classes.moreCount} variant={"body-sm"}>
                {moreReactions}
                {" more"}
              </DiscoText>
            )}
          </DiscoButton>
        </DiscoTooltip>
      )}
    </div>
  )

  function toggleExpandedView(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    setIsExpanded(!isExpanded)
  }
}

const useStyles = makeUseStyles((theme) => ({
  reactionsRow: {
    width: "100%",
    minWidth: "200px",
    display: "flex",
    alignItems: "left",
    gap: theme.spacing(0.5),
    flexWrap: "wrap",
  },
  moreCount: {
    display: "inline-block",
    color: "currentColor",
  },
  button: {
    width: "64px",
    height: "28px",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: `${theme.palette.groovy.neutral[200]} !important`,
    },
  },
  innerTooltip: {
    margin: theme.spacing(1, 0),
    textAlign: "center",
  },
}))

export function ContentUniqueReactionsListSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.reactionsRow}>
      <DiscoButtonSkeleton width={"54px"} height={"24px"} />
      <DiscoButtonSkeleton width={"54px"} height={"24px"} />
      <DiscoButtonSkeleton width={"54px"} height={"24px"} />
      <DiscoButtonSkeleton width={"54px"} height={"24px"} />
      <DiscoButtonSkeleton width={"54px"} height={"24px"} />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ContentUniqueReactionsList,
  skeleton: ContentUniqueReactionsListSkeleton,
})
