import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoLink, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { removeSchemeFromUrl } from "@utils/url/urlUtils"
import classNames from "classnames"

interface Props extends TestIDProps, Spacing {
  image: string
  title: string
  description?: string | null
  url?: string
}

function LinkPreview(props: Props) {
  const {
    image,
    title,
    description,
    url,
    testid = "LinkPreview",
    padding = 1.5,
    ...spacingProps
  } = props

  const truncateClasses = useTruncateStyles({ numberOfLines: 2 })
  const classes = useStyles()
  const spacingClasses = useSpacingStyles({
    padding,
    ...spacingProps,
  })

  return (
    <div
      className={classNames(classes.sharePreview, spacingClasses.root)}
      data-testid={`${testid}.container`}
    >
      <CoverPhoto coverPhoto={image} />
      <div className={classes.textArea}>
        <DiscoText
          variant={"body-sm"}
          className={classNames(classes.title, truncateClasses.truncateLine)}
          testid={`${testid}.title`}
        >
          {title}
        </DiscoText>
        {description && (
          <DiscoText
            variant={"body-xs-500"}
            component={"p"}
            color={"text.secondary"}
            className={truncateClasses.truncateMultipleLines}
            testid={`${testid}.description`}
          >
            {description}
          </DiscoText>
        )}
        {url && (
          <DiscoLink
            className={classNames(classes.url, truncateClasses.truncateLine)}
            href={url}
            target={"_blank"}
            data-testid={`${testid}.link`}
          >
            {removeSchemeFromUrl(url)}
          </DiscoLink>
        )}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  sharePreview: {
    display: "grid",
    width: "100%",
    height: 88 + theme.spacing(1.5) * 2,
    gridTemplateColumns: "176px 1fr",
    gap: theme.spacing(2),
    background: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
  },
  textArea: {
    width: "100%",
    overflow: "hidden",
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    maxWidth: "100%",
    display: "block",
  },
  url: {
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight[500],
    maxWidth: "100%",
  },
}))

export default LinkPreview

const useSpacingStyles = makeSpacingStyles()
