import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { CancelError } from "@/core/network-manager/axiosWithRetry"
import { sendSentryAnException } from "@/core/sentryHandler"
import Relay from "@/relay/relayUtils"
import {
  FinishMultipartUploadPart,
  useMultipartUploadMediaToS3FinishAssetMutation,
} from "@components/media/upload/hooks/__generated__/useMultipartUploadMediaToS3FinishAssetMutation.graphql"
import { useMultipartUploadMediaToS3Mutation } from "@components/media/upload/hooks/__generated__/useMultipartUploadMediaToS3Mutation.graphql"
import { useMultipartUploadMediaToS3StartUploadMutation } from "@components/media/upload/hooks/__generated__/useMultipartUploadMediaToS3StartUploadMutation.graphql"
import {
  CHUNK_SIZE,
  MAX_UPLOAD_ATTEMPTS,
} from "@components/media/upload/util/MediaUploadConstants"
import { getVideoDetails } from "@utils/file/fileUtils"
import { SECOND_IN_MS } from "@utils/time/timeConstants"
import axios from "axios"
import { useRef, useState } from "react"
import { graphql } from "relay-runtime"

type UploadStatus = "idle" | "pending" | "success" | "error"
type ProgressEvent = { loaded: number; total: number }
type AssetSource = "upload" | "ai" | "unsplash"

type UploadOptions = {
  privateObject: boolean
  temporary: boolean
  /** Whether or not we want to include the organizationId on the asset. Some uploads
   * such as user profile pictures shouldn't be associated with an organization
   */
  includeOrganizationId: boolean
  source: AssetSource
}

type MultipartUploadMediaToS3 = {
  mediaFile: File
  includeInMediaLibrary?: boolean
  source?: AssetSource
}

/** The upload result from S3  */
export type S3UploadResult = {
  id: string
  /** Media URL */
  url: string
  /** Size of the media in bytes */
  sizeBytes: number
}

/** Media result can be either from upload or the media library */
export type MediaResult = S3UploadResult & {
  /* filename */
  name: string
}

function useMultipartUploadMediaToS3(
  opts: Partial<UploadOptions> = {
    privateObject: false,
    temporary: false,
    includeOrganizationId: true,
  }
): {
  uploadMediaToS3: (props: MultipartUploadMediaToS3) => Promise<MediaResult>
  uploadProgress: number | null
  uploadStatus: UploadStatus
  cancelCurrentUpload: () => void
} {
  // Requires permission to access/add to the media library
  const activeOrganization = useActiveOrganization()
  const canAccessMediaLibrary = activeOrganization?.viewerPermissions.has("assets.manage")

  const [uploadProgress, setUploadProgress] = useState<number | null>(null)
  const resolvedUrl = useRef<string | null>(null)

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>("idle")
  const controller = useRef<AbortController | null>(null)

  /** Cancel any current upload request */
  function cancelCurrentUpload() {
    // If the upload is cancelled/modal closed/etc, cancel the mutations
    if (controller.current) {
      controller.current.abort()
    }
  }

  const createMultipartUploadUrlMutation =
    Relay.useAsyncMutation<useMultipartUploadMediaToS3Mutation>(
      graphql`
        mutation useMultipartUploadMediaToS3Mutation($input: MultipartUploadInput!) {
          response: createMultipartUploadUrl(input: $input) {
            data
            errors {
              field
              message
            }
          }
        }
      `
    )

  const startUpload =
    Relay.useAsyncMutation<useMultipartUploadMediaToS3StartUploadMutation>(
      graphql`
        mutation useMultipartUploadMediaToS3StartUploadMutation(
          $mediaType: String!
          $fileName: String
          $access: AccessControlList
          $temporary: Boolean
          $organizationId: ID
          $size: Float
          $videoDuration: Float
          $isVisible: Boolean
          $source: AssetSource
        ) {
          startUpload(
            mediaType: $mediaType
            fileName: $fileName
            access: $access
            temporary: $temporary
            organizationId: $organizationId
            size: $size
            videoDuration: $videoDuration
            isVisible: $isVisible
            source: $source
          ) {
            uploadId
            version
          }
        }
      `
    )

  const finishAssetUpload =
    Relay.useAsyncMutation<useMultipartUploadMediaToS3FinishAssetMutation>(
      graphql`
        mutation useMultipartUploadMediaToS3FinishAssetMutation(
          $input: FinishMultipartUploadInput!
        ) {
          finishAssetUpload(input: $input) {
            node {
              id
              url
              sizeBytes
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  /** Get the presignedUrl from GraphQL and upload to S3 */
  async function fetchPartResponse(
    uploadId: string,
    partNumber: number,
    blob: Blob,
    chunksCount: number,
    progressArray: number[]
  ) {
    const index = partNumber - 1
    const { response } = await createMultipartUploadUrlMutation({
      input: { uploadId, partNumber },
    })

    const presignedUrl = response.data!

    // Create a new abort controller for the current upload
    controller.current = new AbortController()

    // Push chunk data to S3 presigned URL
    const partResponse = await axios
      .put(presignedUrl, blob, {
        signal: controller.current.signal,
        onUploadProgress: ({ loaded, total }: ProgressEvent) => {
          if (loaded > total) return

          const currentProgress = Math.round((loaded / total) * 100)

          progressArray[index] = currentProgress
          const sum = progressArray.reduce(
            (acc: number, current: number) => acc + current
          )
          setUploadProgress(Math.round(sum / chunksCount))
        },
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          throw new CancelError("The request was cancelled")
        }
        throw error
      })

    return partResponse.headers.etag
  }

  async function uploadPart(
    blob: Blob,
    uploadId: string,
    partNumber: number,
    chunksCount: number,
    progressArray: number[]
  ): Promise<string> {
    let attempts = 0
    let lastError: unknown = null

    // eslint-disable-next-line no-constant-condition
    while (true) {
      attempts++
      if (attempts > MAX_UPLOAD_ATTEMPTS)
        throw new Error(`Max attempt limit reached, last error was: ${lastError}`)

      try {
        return await fetchPartResponse(
          uploadId,
          partNumber,
          blob,
          chunksCount,
          progressArray
        )
      } catch (error) {
        // If request is aborted
        if (error instanceof CancelError) {
          throw error
        }
        lastError = error
        if (attempts <= MAX_UPLOAD_ATTEMPTS) {
          // Wait linearly increasing duration between attempting again
          const retryTimeout = attempts * SECOND_IN_MS * 2
          await new Promise((res) => setTimeout(res, retryTimeout))
        }
      }
    }
  }

  /**
   * finalizeUploadToS3 is called to finish an upload, this will retry up to max times
   */
  async function finalizeUploadToS3(
    uploadId: string,
    parts: FinishMultipartUploadPart[],
    version?: number | null
  ): Promise<S3UploadResult> {
    let attempts = 0
    let lastError: unknown = null

    // eslint-disable-next-line no-constant-condition
    while (true) {
      attempts++
      if (attempts > MAX_UPLOAD_ATTEMPTS)
        throw new Error(
          `Max finalize attempt limit reached, last error was: ${lastError}`
        )

      if (version && version !== 2) {
        // Doesn't look like we're using this anymore but throwing this error in case
        // we are still using an old version somewhere. If we are, should convert it to use
        // the new method for uploading assets
        throw new Error("Using an outdated method of uploading to S3")
      }

      try {
        // Check if we are using the new asset upload that will create an object in
        // the asset table or the original one that just returns the URL
        const { finishAssetUpload: node } = await finishAssetUpload({
          input: { uploadId, parts },
        })
        if (!node.node) throw new Error(`Failed ${node.errors}`)
        return node.node
      } catch (error) {
        lastError = error
        if (attempts <= MAX_UPLOAD_ATTEMPTS) {
          // Wait linearly increasing duration between attempting again
          const retryTimeout = attempts * SECOND_IN_MS * 2
          await new Promise((res) => setTimeout(res, retryTimeout))
        }
      }
    }
  }

  /**
   * Upload a file to S3 using multipart upload
   *
   * @param mediaFile A file to upload to S3
   * @returns A promise that resolves to the S3 URL of the uploaded file, if the upload was cancelled, the promise resolves to an empty string
   */
  async function multipartUploadMediaToS3({
    mediaFile,
    includeInMediaLibrary,
    source,
  }: MultipartUploadMediaToS3): Promise<MediaResult> {
    cancelCurrentUpload()

    setUploadProgress(0)
    setUploadStatus("pending")

    try {
      let video: HTMLVideoElement | null = null
      if (mediaFile.type.includes("video")) {
        video = await getVideoDetails(mediaFile)
      }

      const { startUpload: data } = await startUpload({
        mediaType: mediaFile.type,
        fileName: mediaFile.name,
        access: opts.privateObject ? "private" : "public",
        temporary: opts.temporary ?? false,
        organizationId: opts.includeOrganizationId ? activeOrganization?.id : undefined,
        size: mediaFile.size,
        videoDuration: video ? Math.floor(video.duration) : null,
        isVisible: canAccessMediaLibrary ? includeInMediaLibrary ?? true : false,
        source: source ?? "upload",
      })

      const fileSize = mediaFile.size
      const chunksCount = Math.floor(fileSize / CHUNK_SIZE) + 1
      const parts: FinishMultipartUploadPart[] = []
      const progressArray: number[] = []

      // Upload each chunk
      for (let partNumber = 1; partNumber < chunksCount + 1; partNumber++) {
        const index = partNumber - 1
        const start = index * CHUNK_SIZE
        const end = partNumber * CHUNK_SIZE
        const blob =
          partNumber < chunksCount ? mediaFile.slice(start, end) : mediaFile.slice(start)

        const eTag = await uploadPart(
          blob,
          data.uploadId,
          partNumber,
          chunksCount,
          progressArray
        )

        parts.push({
          eTag: eTag.toString(),
          partNumber,
        })
      }

      // Finish the upload
      const result = await finalizeUploadToS3(data.uploadId, parts, data.version)

      if (CDN_DOMAIN)
        resolvedUrl.current = result.url.replace(
          /^https:\/\/.*s3.amazonaws.com\/(.*)/g,
          `https://${CDN_DOMAIN}/$1`
        )
      else resolvedUrl.current = result.url
      setUploadProgress(null)
      setUploadStatus("success")
      return Promise.resolve({
        ...result,
        url: resolvedUrl.current,
        // Original filename
        name: mediaFile.name,
      })
    } catch (error) {
      setUploadProgress(null)
      setUploadStatus("error")
      if (error instanceof CancelError) {
        return Promise.reject(error)
      }

      // Send the error to sentry
      sendSentryAnException(error, {
        extra: {
          title: "useMultipartUploadMediaToS3",
        },
      })
      return Promise.reject(Error("Media upload failed."))
    }
  }

  return {
    uploadMediaToS3: multipartUploadMediaToS3,
    uploadProgress,
    uploadStatus,
    cancelCurrentUpload,
  }
}

export default useMultipartUploadMediaToS3
