import { GlobalID } from "@/relay/RelayTypes"
import PollSetupModal from "@/web-form/editor/poll/PollSetupModal"
import {
  $createPollNode,
  PollNode,
  PollPayload,
} from "@components/editor/plugins/poll/PollNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils"
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical"
import { useEffect, useState } from "react"

export const INSERT_POLL_COMMAND = createCommand<PollPayload>("insertPoll")

export const UPDATE_POLL_COMMAND = createCommand<PollPayload>("updatePoll")

export function PollPlugin() {
  const [editor] = useLexicalComposerContext()
  const [updateWebFormId, setUpdateWebFormId] = useState<null | GlobalID>(null)

  useEffect(() => {
    if (!editor.hasNodes([PollNode])) {
      throw new Error("PollPlugin: PollNode not registered on editor")
    }
    return mergeRegister(
      editor.registerCommand(
        INSERT_POLL_COMMAND,
        (payload) => {
          $insertNodeToNearestRoot($createPollNode(payload))
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        UPDATE_POLL_COMMAND,
        (payload) => {
          setUpdateWebFormId(payload.webFormId)
          return false
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  if (!updateWebFormId) return null
  return (
    <PollSetupModal
      isOpen={Boolean(updateWebFormId)}
      webFormId={updateWebFormId}
      onCancel={() => setUpdateWebFormId(null)}
      onSave={() => setUpdateWebFormId(null)}
    />
  )
}
