import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import ContentEmoji from "@components/reactions/ContentEmoji"
import { ContentEmojiButtonPreloadedQuery } from "@components/reactions/ContentEmojiButton"
import { ContentEmojiButtonTooltipPreloadedQuery } from "@components/reactions/__generated__/ContentEmojiButtonTooltipPreloadedQuery.graphql"
import { DiscoText } from "@disco-ui"
import { BaseEmoji } from "emoji-mart"
import { observer } from "mobx-react-lite"
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay"

type ContentEmojiButtonTooltipProps = {
  emoji: BaseEmoji
  occurrenceQueryRef: PreloadedQuery<ContentEmojiButtonTooltipPreloadedQuery>
  hasViewerReacted: boolean
  reactionCount: number
}

function ContentEmojiButtonTooltip(props: ContentEmojiButtonTooltipProps) {
  const classes = useStyles()
  const { emoji, occurrenceQueryRef, hasViewerReacted, reactionCount } = props

  const { node } = usePreloadedQuery<ContentEmojiButtonTooltipPreloadedQuery>(
    ContentEmojiButtonPreloadedQuery,
    occurrenceQueryRef
  )

  const emojiAlias = emoji.colons
  const { authUser } = useAuthUser()
  const content = Relay.narrowNodeType(node, "Content")
  const users = Relay.connectionToArray(content?.reactions)

  if (!users) return null

  function renderUserNames() {
    const reactions = Relay.connectionToArray(content?.reactions)
    const names = hasViewerReacted ? ["You"] : []

    reactions.forEach((r) => {
      if (r.user.id !== authUser?.id) {
        names.push(r.user.firstName ?? "Someone")
      }
    })

    const additionalReactions = reactionCount - names.length

    if (additionalReactions > 0) {
      names.push(`${additionalReactions} others`)
    }

    return names.length > 1
      ? `${names.slice(0, -1).join(", ")} and ${names[names.length - 1]}`
      : names[0] || ""
  }

  return (
    <div className={classes.innerTooltip}>
      <div className={classes.emojiWrapper}>
        <ContentEmoji emoji={emoji} size={40} />
      </div>
      <DiscoText variant={"body-sm"} color={"common.white"}>
        {renderUserNames()}
        {` reacted with ${emojiAlias}`}
      </DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  innerTooltip: {
    margin: theme.spacing(1, 0),
    textAlign: "center",
  },
  emojiWrapper: {
    backgroundColor: theme.palette.common.white,
    display: "inline-block",
    borderRadius: theme.measure.borderRadius.default,
    height: "40px",
  },
}))

export default observer(ContentEmojiButtonTooltip)

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ContentEmojiButtonTooltipFragment on ContentLike {
    id
    user {
      firstName
      lastName
      id
    }
  }
`
