// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/FloatingTextFormatToolbarPlugin/index.tsx
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { EditorBlockType } from "@components/editor/config/LexicalNodes"
import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import EditorToolbar, {
  EditorToolbarDivider,
  EditorToolbarGroup,
} from "@components/editor/plugins/toolbar/EditorToolbar"
import EditorToolbarFontColorButton from "@components/editor/plugins/toolbar/EditorToolbarFontColorButton"
import EditorToolbarHighlightColorButton from "@components/editor/plugins/toolbar/EditorToolbarHighlightColorButton"
import EditorToolbarLinkButton from "@components/editor/plugins/toolbar/EditorToolbarLinkButton"
import EditorToolbarTextAlignButton from "@components/editor/plugins/toolbar/EditorToolbarTextAlignButton"
import EditorToolbarTextFormatButtons from "@components/editor/plugins/toolbar/EditorToolbarTextFormatButtons"
import EditorToolbarConvertButton from "@components/editor/plugins/toolbar/convert/EditorToolbarConvertButton"
import { mergeRegister } from "@lexical/utils"
import {
  $getSelection,
  COMMAND_PRIORITY_LOW,
  ElementFormatType,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from "lexical"
import { useCallback, useEffect, useRef } from "react"

function TextToolbar({
  editor,
  anchorElem,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isStrikethrough,
  isCode,
  alignment,
  fontColor,
  highlightColor,
  blockType,
}: {
  editor: LexicalEditor
  anchorElem: HTMLElement
  isBold: boolean
  isItalic: boolean
  isLink: boolean
  isStrikethrough: boolean
  isUnderline: boolean
  isCode: boolean
  fontColor: string
  highlightColor: string
  alignment: ElementFormatType
  blockType: EditorBlockType
}): JSX.Element {
  const { isBlockOptionsOpen, config } = useLexicalEditorContext()

  const textToolbarRef = useRef<HTMLDivElement | null>(null)

  function mouseMoveListener(e: MouseEvent) {
    if (textToolbarRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      if (textToolbarRef.current.style.pointerEvents !== "none") {
        const x = e.clientX
        const y = e.clientY
        const elementUnderMouse = document.elementFromPoint(x, y)

        if (!textToolbarRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          textToolbarRef.current.style.pointerEvents = "none"
        }
      }
    }
  }
  function mouseUpListener() {
    if (textToolbarRef?.current) {
      if (textToolbarRef.current.style.pointerEvents !== "auto") {
        textToolbarRef.current.style.pointerEvents = "auto"
      }
    }
  }

  useEffect(() => {
    if (textToolbarRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener)
      document.addEventListener("mouseup", mouseUpListener)

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener)
        document.removeEventListener("mouseup", mouseUpListener)
      }
    }

    return undefined
  }, [textToolbarRef])

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection()

    const textToolbarElem = textToolbarRef.current
    const nativeSelection = window.getSelection()

    if (textToolbarElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = LexicalUtils.getDOMRangeRect(nativeSelection, rootElement)

      LexicalUtils.setFloatingElemPosition(rangeRect, textToolbarElem, anchorElem)
    }
  }, [editor, anchorElem])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar()
      })
    }

    window.addEventListener("resize", update)
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [editor, updateTextFormatFloatingToolbar, anchorElem])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, updateTextFormatFloatingToolbar])

  const classes = useStyles()

  return (
    <EditorToolbar ref={textToolbarRef} className={classes.toolbar}>
      {editor.isEditable() && !isBlockOptionsOpen && (
        <>
          <EditorToolbarGroup>
            <EditorToolbarConvertButton blockType={blockType} />
          </EditorToolbarGroup>
          {config.inlineTools.has("link") && (
            <>
              <EditorToolbarDivider />
              <EditorToolbarGroup>
                <EditorToolbarLinkButton isLink={isLink} />
              </EditorToolbarGroup>
            </>
          )}
          {(config.inlineTools.has("bold") ||
            config.inlineTools.has("italic") ||
            config.inlineTools.has("underline") ||
            config.inlineTools.has("strikethrough") ||
            config.inlineTools.has("code")) && (
            <>
              <EditorToolbarDivider />
              <EditorToolbarGroup>
                <EditorToolbarTextFormatButtons
                  isBold={isBold}
                  isItalic={isItalic}
                  isUnderline={isUnderline}
                  isStrikethrough={isStrikethrough}
                  isCode={isCode}
                />
              </EditorToolbarGroup>
            </>
          )}
          {config.inlineTools.has("color") && (
            <>
              <EditorToolbarDivider />
              <EditorToolbarGroup>
                <EditorToolbarFontColorButton fontColor={fontColor} />
                <EditorToolbarHighlightColorButton highlightColor={highlightColor} />
              </EditorToolbarGroup>
            </>
          )}
          {config.inlineTools.has("alignment") && (
            <>
              <EditorToolbarDivider />
              <EditorToolbarGroup>
                <EditorToolbarTextAlignButton
                  alignment={alignment}
                  onChange={(option) =>
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, option)
                  }
                />
              </EditorToolbarGroup>
            </>
          )}
        </>
      )}
    </EditorToolbar>
  )
}

const useStyles = makeUseStyles((theme) => ({
  toolbar: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.overlay,
    opacity: 0,
    transition: "opacity 0.5s",
    willChange: "transform",

    [theme.breakpoints.down("xs")]: {
      left: "36px",
    },
  },
}))

export default TextToolbar
