/**
 * @generated SignedSource<<1987aa2fbfded6dab1415713151eff24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccessControlList = "public" | "private" | "%future added value";
export type AssetSource = "upload" | "ai" | "unsplash" | "%future added value";
export type useMultipartUploadMediaToS3StartUploadMutation$variables = {
  mediaType: string;
  fileName?: string | null;
  access?: AccessControlList | null;
  temporary?: boolean | null;
  organizationId?: string | null;
  size?: number | null;
  videoDuration?: number | null;
  isVisible?: boolean | null;
  source?: AssetSource | null;
};
export type useMultipartUploadMediaToS3StartUploadMutation$data = {
  readonly startUpload: {
    readonly uploadId: string;
    readonly version: number | null;
  };
};
export type useMultipartUploadMediaToS3StartUploadMutation = {
  variables: useMultipartUploadMediaToS3StartUploadMutation$variables;
  response: useMultipartUploadMediaToS3StartUploadMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "access"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isVisible"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mediaType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "size"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "temporary"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoDuration"
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "access",
        "variableName": "access"
      },
      {
        "kind": "Variable",
        "name": "fileName",
        "variableName": "fileName"
      },
      {
        "kind": "Variable",
        "name": "isVisible",
        "variableName": "isVisible"
      },
      {
        "kind": "Variable",
        "name": "mediaType",
        "variableName": "mediaType"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "size",
        "variableName": "size"
      },
      {
        "kind": "Variable",
        "name": "source",
        "variableName": "source"
      },
      {
        "kind": "Variable",
        "name": "temporary",
        "variableName": "temporary"
      },
      {
        "kind": "Variable",
        "name": "videoDuration",
        "variableName": "videoDuration"
      }
    ],
    "concreteType": "MultipartUploadUrl",
    "kind": "LinkedField",
    "name": "startUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useMultipartUploadMediaToS3StartUploadMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "useMultipartUploadMediaToS3StartUploadMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "a94817312cf3995b2105a4d34a1f2b5f",
    "id": null,
    "metadata": {},
    "name": "useMultipartUploadMediaToS3StartUploadMutation",
    "operationKind": "mutation",
    "text": "mutation useMultipartUploadMediaToS3StartUploadMutation(\n  $mediaType: String!\n  $fileName: String\n  $access: AccessControlList\n  $temporary: Boolean\n  $organizationId: ID\n  $size: Float\n  $videoDuration: Float\n  $isVisible: Boolean\n  $source: AssetSource\n) {\n  startUpload(mediaType: $mediaType, fileName: $fileName, access: $access, temporary: $temporary, organizationId: $organizationId, size: $size, videoDuration: $videoDuration, isVisible: $isVisible, source: $source) {\n    uploadId\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1c68c3020d6d3f5472f90a3d1e0299d";

export default node;
