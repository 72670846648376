import React, { ChangeEvent, HTMLProps, useRef } from "react"

type Props = {
  onChange(files: File[]): void
  renderButton: React.FC<ButtonProps>
} & Pick<HTMLProps<HTMLInputElement>, "accept" | "multiple">

interface ButtonProps {
  onClick(): void
}

const DiscoFileInput: React.FC<Props> = ({ onChange, renderButton, ...inputProps }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const CustomButton = renderButton

  return (
    <>
      <CustomButton onClick={() => inputRef.current?.click()} />
      <input
        ref={(e) => (inputRef.current = e)}
        type={"file"}
        onChange={handleChange}
        style={{ display: "none" }}
        {...inputProps}
      />
    </>
  )

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return
    onChange(Array.from(e.target.files))
  }
}

export default DiscoFileInput
