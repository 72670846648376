import { GlobalID } from "@/relay/RelayTypes"
import PollSetupModal from "@/web-form/editor/poll/PollSetupModal"
import { INSERT_POLL_COMMAND } from "@components/editor/plugins/poll/PollPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  isOpen: boolean
  onClose: VoidFunction
}

function EditorPollSetupModal(props: Props) {
  const { isOpen, onClose } = props
  const [editor] = useLexicalComposerContext()

  if (!isOpen) return null

  return <PollSetupModal isOpen onCancel={onClose} onSave={handleSave} />

  function handleSave(webFormId: GlobalID) {
    editor.dispatchCommand(INSERT_POLL_COMMAND, { webFormId })
    onClose()
  }
}

export default observer(EditorPollSetupModal)
