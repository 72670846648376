/**
 * @generated SignedSource<<8dbf8f651836787b404539f3fc4eba8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentUniqueReactionsListContentUsageQuery$variables = {
  id: string;
};
export type ContentUniqueReactionsListContentUsageQuery$data = {
  readonly contentUsage: {
    readonly id?: string;
    readonly contentId?: string;
    readonly uniqueReactions?: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ContentEmojiButton_ContentReactionFragment">;
        };
      }>;
    };
  } | null;
};
export type ContentUniqueReactionsListContentUsageQuery = {
  variables: ContentUniqueReactionsListContentUsageQuery$variables;
  response: ContentUniqueReactionsListContentUsageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentId",
  "storageKey": null
},
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentUniqueReactionsListContentUsageQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentReactionConnection",
                "kind": "LinkedField",
                "name": "uniqueReactions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentReactionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentReaction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ContentEmojiButton_ContentReactionFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentUniqueReactionsListContentUsageQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentReactionConnection",
                "kind": "LinkedField",
                "name": "uniqueReactions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentReactionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentReaction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasViewerReacted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emojiCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emojiAlias",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emojiCount",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a750d3e7a2b05a10b530b87e5335b9d",
    "id": null,
    "metadata": {},
    "name": "ContentUniqueReactionsListContentUsageQuery",
    "operationKind": "query",
    "text": "query ContentUniqueReactionsListContentUsageQuery(\n  $id: ID!\n) {\n  contentUsage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      id\n      contentId\n      uniqueReactions {\n        edges {\n          node {\n            id\n            ...ContentEmojiButton_ContentReactionFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ContentEmojiButton_ContentReactionFragment on ContentReaction {\n  id\n  hasViewerReacted\n  emojiCode\n  emojiAlias\n  emojiCount\n}\n"
  }
};
})();

(node as any).hash = "1d9831131f8d10ce43324b235c4db649";

export default node;
