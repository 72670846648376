import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText } from "@disco-ui"
import classNames from "classnames"

export type CountBadgeProps = {
  count: number | string
  testid?: string
  className?: string
  size?: number
  color?: string
  backgroundColor?: string
  maxCount?: number
  fontSize?: number
}

const DEFAULT_BADGE_SIZE = 22

function CountBadge({
  count,
  testid,
  className,
  color,
  backgroundColor,
  size = DEFAULT_BADGE_SIZE,
  maxCount = 99,
  fontSize,
  ...props
}: CountBadgeProps) {
  const maxExceeded = maxCount > 0 && Number(count) > maxCount
  const classes = useStyles({ size, color, backgroundColor, maxExceeded, fontSize })
  const TESTID = `${testid ? `${testid}.` : ""}CountBadge`
  return (
    <div data-testid={TESTID} className={classNames(classes.badge, className)} {...props}>
      <DiscoText data-testid={`${TESTID}.count`} component={"span"}>
        {maxExceeded ? `${maxCount}+` : count}
      </DiscoText>
    </div>
  )
}

interface StyleProps {
  size: number
  color?: string
  backgroundColor?: string
  maxExceeded: boolean
  fontSize?: number
}

const useStyles = makeUseStyles((theme) => ({
  badge: (props: StyleProps) => ({
    width: `calc(${props.size}px + 4px)`,
    height: props.size,
    backgroundColor: props.backgroundColor || theme.palette.error.main,
    borderRadius: theme.measure.borderRadius.big,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Add the default font size on parent so "em" units will work on the span
    ...theme.typography["body-xs"],
    ...theme.typography.modifiers.fontWeight[500],
    ...styleIf(props.fontSize, { fontSize: props.fontSize }),
    "& span": {
      // !important necessary when CountBadge nested in DiscoButton to override specificity of
      // span's css color set by hover state of DiscoButton
      color: `${props.color || theme.palette.common.white} !important`,
      fontSize: props.maxExceeded ? "0.8em" : "inherit",
      fontWeight: "inherit",
      lineHeight: "inherit",
    },
  }),
}))

export default CountBadge
