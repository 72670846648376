import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorColorPicker from "@components/editor/components/EditorColorPicker"
import { useEditorToolbarDropdown } from "@components/editor/plugins/toolbar/EditorToolbar"
import EditorToolbarButton from "@components/editor/plugins/toolbar/EditorToolbarButton"
import { DiscoIcon } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $patchStyleText } from "@lexical/selection"
import { Popover } from "@material-ui/core"
import { $getSelection, $isRangeSelection } from "lexical"
import { useCallback, useRef, useState } from "react"

interface EditorToolbarHighlightColorButtonProps {
  highlightColor: string
}

function EditorToolbarHighlightColorButton({
  highlightColor,
}: EditorToolbarHighlightColorButtonProps) {
  const [editor] = useLexicalComposerContext()

  const { isOpen, onOpen, onClose } = useEditorToolbarDropdown()

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  // highlightColor will be affected by text on either side of the selection, so instead show the selectedColor
  const [selectedColor, setSelectedColor] = useState<string | null>(highlightColor)

  const classes = useStyles()

  const onHighlightColorSelect = useCallback(
    (value: string | null) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, { "background-color": value })
        }
        setSelectedColor(value)
        onClose()
      })
    },
    [editor, onClose]
  )

  return (
    <>
      <div>
        <EditorToolbarButton
          ref={buttonRef}
          isActive={false}
          onClick={onOpen}
          tooltip={"Highlight"}
          icon={
            <div className={classes.icon}>
              <DiscoIcon icon={"brush"} />
              <div
                style={selectedColor ? { backgroundColor: selectedColor } : undefined}
                className={classes.color}
              />
            </div>
          }
          ariaLabel={"Apply a highlight to selected text"}
        />
      </div>
      {buttonRef.current && (
        <Popover
          open={isOpen}
          onClose={onClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ paper: classes.popover }}
          anchorEl={buttonRef.current}
        >
          <EditorColorPicker
            variant={"highlight"}
            onChange={(hex) => onHighlightColorSelect(hex)}
          />
        </Popover>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  popover: {
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.default,
    display: "flex",
    padding: theme.spacing(0.25),
    marginTop: theme.spacing(0.5),
  },
  icon: {
    display: "grid",
    position: "relative",
    paddingRight: theme.spacing(0.5),
  },
  color: {
    position: "absolute",
    bottom: 0,
    right: "1px",
    width: "8px",
    height: "8px",
    borderRadius: "2px",
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
  },
}))

export default EditorToolbarHighlightColorButton
