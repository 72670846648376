import ExpandIcon from "@/core/ui/iconsax/linear/custom-expand.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { useTheme } from "@material-ui/core/styles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { Resizable, ResizableProps } from "re-resizable"

interface ResizableContainerProps extends TestIDProps, ResizableProps {
  children: React.ReactNode
  className?: string
}

function ResizableContainer({
  children,
  className,
  ...resizableProps
}: ResizableContainerProps) {
  const showOnHoverClasses = useShowOnHoverStyles()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Resizable
      {...resizableProps}
      className={classNames(showOnHoverClasses.hoverable, classes.container, className)}
    >
      {children}
      <ExpandIcon
        height={18}
        width={18}
        className={classNames(showOnHoverClasses.showable, classes.icon)}
        color={theme.palette.text.primary}
      />
    </Resizable>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
  },

  icon: {
    position: "absolute",
    right: "-6px",
    bottom: 0,
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.default,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

export default ResizableContainer
