import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { LocationState, isDiscoDomain } from "@/core/route/util/routeUtils"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { SocialLoginGetSocialLoginRedirectUrlMutation } from "@components/social-login/__generated__/SocialLoginGetSocialLoginRedirectUrlMutation.graphql"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { Grid } from "@material-ui/core"
import { OAuthGetURLOptions } from "@stytch/core/public"
import { StytchProvider, useStytch } from "@stytch/react"
import { OAuthStartResponse, StytchHeadlessClient } from "@stytch/vanilla-js/headless"
import { useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"
import { encodeBase64 } from "stream-chat"
import SocialAuthProviderButton from "./SocialAuthProviderButton"

export enum SocialLoginProvider {
  google = "google",
  apple = "apple",
}

export interface SocialLoginProps {
  /** redirect destination URL */
  redirectDestination?: string
  labelPrefix?: string
  blockUserSignup?: boolean
  providers?: SocialLoginProvider[]
  disabled?: boolean
  width?: string
  onClick?: () => boolean
  children?: React.ReactNode
}

// Initialize stytch
const stytch = new StytchHeadlessClient(STYTCH_PUBLIC_TOKEN)

function SocialLogin(props: SocialLoginProps) {
  return (
    <StytchProvider stytch={stytch}>
      <SocialLoginSelector {...props} />
    </StytchProvider>
  )
}

export type SocialLoginDestination = {
  locationState?: LocationState
  blockUserSignup?: boolean
}

function SocialLoginSelector(props: SocialLoginProps) {
  const stytchClient = useStytch()
  const activeOrganization = useActiveOrganization()

  const {
    providers = [SocialLoginProvider.google, SocialLoginProvider.apple],
    labelPrefix,
    redirectDestination,
    blockUserSignup,
    disabled,
    width,
    onClick,
    children,
  } = props

  const classes = useStyles()

  const location = useLocation<LocationState>()

  // If we've been given a redirect URL add it to the location state
  if (redirectDestination) {
    if (!location.state) {
      location.state = {}
    }
    location.state.redirectUrl = redirectDestination
  } else if (!isDiscoDomain(window.location.host) && !location.state?.redirectUrl) {
    // Otherwise if we are on a custom domain and there is no redirect URL, then we should redirect
    // to the root of that domain after authenticating to let the router
    // figure out what to do after we auth
    if (!location.state) {
      location.state = {}
    }
    location.state.redirectUrl = `https://${window.location.host}/home`
  }

  // Setup our destination
  const destination: SocialLoginDestination = {
    locationState: location.state,
    blockUserSignup,
  }

  const getSocialLoginRedirectUrl =
    Relay.useAsyncMutation<SocialLoginGetSocialLoginRedirectUrlMutation>(graphql`
      mutation SocialLoginGetSocialLoginRedirectUrlMutation {
        getSocialLoginRedirectUrl {
          data
          errors {
            field
            message
          }
        }
      }
    `)

  return (
    <>
      {isSocialAuthEnabled() && !activeOrganization?.authProvider && (
        <>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            className={classes.itemContainer}
          >
            {providers.map((provider) => {
              return (
                <SocialAuthProviderButton
                  key={provider}
                  provider={provider}
                  labelPrefix={labelPrefix}
                  disabled={disabled}
                  width={width}
                  onClick={getSocialLoginHandler(provider)}
                />
              )
            })}
          </Grid>
          {children}
        </>
      )}
    </>
  )

  function getSocialLoginStartFn(
    provider: SocialLoginProvider
  ): (options?: OAuthGetURLOptions) => Promise<OAuthStartResponse> {
    switch (provider) {
      case SocialLoginProvider.google:
        return stytchClient.oauth.google.start
      case SocialLoginProvider.apple:
        return stytchClient.oauth.apple.start
    }
  }

  function getSocialLoginHandler(provider: SocialLoginProvider): () => Promise<void> {
    return async () => {
      if (onClick && !onClick()) {
        return
      }
      const start = getSocialLoginStartFn(provider)

      const res = await getSocialLoginRedirectUrl({})
      if (res.getSocialLoginRedirectUrl.data) {
        const u = new URL(res.getSocialLoginRedirectUrl.data)

        u.searchParams.set("destination", encodeBase64(JSON.stringify(destination)))

        // Start the oauth connection
        const redirectUrl = u.toString()
        await start({
          login_redirect_url: redirectUrl,
          signup_redirect_url: redirectUrl,
        })
      } else {
        let errorMessage = "Unknown error has occurred, please try again later."
        if (res.getSocialLoginRedirectUrl.errors?.length) {
          errorMessage = res.getSocialLoginRedirectUrl.errors[0].message
        }
        displayErrorToast(errorMessage)
      }
    }
  }
}

/**
 *
 * Returns whether social auth is enabled, specifically used right now for in-app browsers that cannot login with google
 */
export function isSocialAuthEnabled() {
  const { userAgent } = window.navigator
  return !(
    userAgent.includes("LinkedInApp") || // For LinkedIn
    userAgent.includes("FBAV") || // For Facebook
    userAgent.includes("FBAN") ||
    userAgent.includes("FBBV") ||
    userAgent.includes("FBDV") ||
    userAgent.includes("FBSN") ||
    userAgent.includes("FBMD") ||
    userAgent.includes("FBID")
  )
}

export default SocialLogin

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    background: theme.palette.background.paper,
    borderBottomLeftRadius: theme.measure.borderRadius.medium,
    borderBottomRightRadius: theme.measure.borderRadius.medium,
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
