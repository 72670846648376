import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import { initialCropModalState } from "@components/dropzone/FileDropzone"
import ImageCropModal from "@components/image/crop-modal/ImageCropModal"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import DiscoFileInput from "@components/input/file/DiscoFileInput"
import useMultipartUploadMediaToS3, {
  MediaResult,
} from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonProps, useDiscoButtonColorStyle } from "@disco-ui"
import { convertFileToBase64, urlToFile } from "@utils/file/fileUtils"
import React, { useState } from "react"
import { Accept } from "react-dropzone"

type MediaUploadButtonProps = Omit<DiscoButtonProps, "children" | "onClick"> & {
  onSuccess: (result: MediaResult, file: File) => void
  accept: Accept
  cropperProps?: ImageCropModalCropperProps
  children:
    | React.FC<{ onClick(): void; uploadProgress: number | null }>
    | string
    | React.ReactElement
  testid?: string
  includeOrganizationIdOnAsset?: boolean
  includeInMediaLibrary?: boolean
}

function MediaUploadButton({
  onSuccess,
  accept,
  children,
  color = "primary",
  disabled,
  className,
  testid,
  cropperProps,
  includeOrganizationIdOnAsset = true,
  includeInMediaLibrary,
  ...rest
}: MediaUploadButtonProps) {
  const { uploadMediaToS3, uploadProgress } = useMultipartUploadMediaToS3({
    includeOrganizationId: includeOrganizationIdOnAsset,
  })

  const [cropModalState, setCropModalState] = useState(initialCropModalState)

  const isDisabled = disabled || uploadProgress !== null
  const colorStyle = useDiscoButtonColorStyle(color)

  return (
    <>
      <DiscoFileInput
        data-testid={`${testid}-file-input`}
        accept={Object.keys(accept).join(",")}
        onChange={handleUpload}
        renderButton={(props) => {
          if (React.isValidElement(children) || typeof children === "string") {
            return (
              <DiscoButton
                onClick={props.onClick}
                color={color}
                disabled={isDisabled}
                className={className}
                testid={testid}
                {...rest}
              >
                {uploadProgress === null ? (
                  children
                ) : (
                  <CircularProgressWithLabel
                    value={uploadProgress}
                    color={isDisabled ? undefined : colorStyle.color}
                  />
                )}
              </DiscoButton>
            )
          }
          return React.createElement(children, {
            ...rest,
            ...props,
            uploadProgress,
          })
        }}
      />
      {cropperProps && cropModalState.isOpen && (
        <ImageCropModal
          isOpen={cropModalState.isOpen}
          onClose={() => setCropModalState(initialCropModalState)}
          imageSrc={cropModalState.imgSrc}
          cropperProps={cropperProps}
          onCrop={handleCrop}
        />
      )}
    </>
  )

  async function handleUpload(files: File[]) {
    const file = files[0]
    if (!file) return

    // Don't crop gifs because it breaks animation
    if (cropperProps && file.type !== "image/gif") {
      setCropModalState({
        isOpen: true,
        imgSrc: await convertFileToBase64(file),
        file,
      })
      return
    }

    try {
      const result = await uploadMediaToS3({
        mediaFile: file,
        includeInMediaLibrary,
      })
      onSuccess(result, file)
    } catch (error) {
      displayErrorToast(Error("Upload failed, please try again later."))
    }
  }

  async function handleCrop(base64: string) {
    // Hide crop modal and process upload
    setCropModalState(initialCropModalState)
    const result = await uploadMediaToS3({
      mediaFile: await urlToFile(
        base64,
        cropModalState.file!.name,
        cropModalState.file!.type
      ),
      includeInMediaLibrary,
    })

    if (!onSuccess) return
    onSuccess(result, cropModalState.file!)
  }
}

export default MediaUploadButton
