import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { TextVariant } from "@assets/style/appMuiTheme"
import { DiscoTextButton } from "@disco-ui"
import { Collapse } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import useResizeObserver from "@utils/hook/useResizeObserver"
import classNames from "classnames"
import React, { useRef, useState } from "react"

interface ReadMoreButtonProps {
  children: React.ReactNode
  maxContentHeight: number | undefined
  marginBottom?: number
  textVariant?: TextVariant
  className?: string
  classes?: ClassNameMap<"button">
}

function ReadMoreButton({
  children,
  maxContentHeight,
  marginBottom,
  textVariant,
  className,
  classes: propsClasses,
}: ReadMoreButtonProps) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [contentHeight, setContentHeight] = useState<number>()
  const showReadMoreButton = Boolean(
    maxContentHeight && contentHeight && contentHeight >= maxContentHeight
  )

  const contentRef = useRef<HTMLDivElement>(null)

  useResizeObserver(contentRef, (el) => {
    setContentHeight(el.clientHeight)
  })

  const classes = useStyles({ marginBottom })

  if (!maxContentHeight) return <>{children}</>

  return (
    <div className={classNames(classes.container, className)}>
      <Collapse
        in={isExpanded}
        collapsedSize={`${
          contentHeight && contentHeight < maxContentHeight
            ? contentHeight
            : maxContentHeight
        }px`}
        timeout={0}
      >
        <div ref={contentRef} data-testid={"ReadMoreButtonNode"}>
          {children}
        </div>
      </Collapse>
      {showReadMoreButton && (
        <DiscoTextButton
          className={classNames(classes.readMoreButton, propsClasses?.button)}
          onClick={toggleContent}
          textVariant={textVariant}
        >
          {isExpanded ? "Show Less" : "Show More..."}
        </DiscoTextButton>
      )}
    </div>
  )

  function toggleContent(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    setIsExpanded((prev) => !prev)
  }
}

type StyleProps = {
  marginBottom?: number
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ marginBottom }: StyleProps) => ({
    marginBottom: marginBottom ? theme.spacing(marginBottom) : "unset",
  }),
  readMoreButton: {
    marginTop: theme.spacing(2),
  },
}))

export default ReadMoreButton
