import EditorVideo from "@components/editor/plugins/video/EditorVideo"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical"

export interface VideoPayload {
  key?: NodeKey
  src: string
  assetId: string
  assetUsageId?: string
  alignment?: ElementFormatType
  widthPercentage?: number
}

export type SerializedVideoNode = Spread<
  {
    src: string
    assetId: string
    assetUsageId?: string
    alignment?: ElementFormatType
    widthPercentage?: number
  },
  SerializedDecoratorBlockNode
>

export class VideoNode extends DecoratorBlockNode {
  __src: string
  __assetId: string
  __assetUsageId?: string
  __alignment?: ElementFormatType
  __widthPercentage?: number

  static getType(): string {
    return "video"
  }

  static clone(node: VideoNode): VideoNode {
    return new VideoNode(
      {
        src: node.__src,
        assetId: node.__assetId,
        assetUsageId: node.__assetUsageId,
        alignment: node.__alignment,
        widthPercentage: node.__widthPercentage,
      },
      node.__key,
      node.__format
    )
  }

  static importJSON(serializedNode: SerializedVideoNode): VideoNode {
    const { alignment, assetId, assetUsageId, widthPercentage, src } = serializedNode
    const node = $createVideoNode({
      alignment,
      assetId,
      assetUsageId,
      widthPercentage,
      src,
    })
    return node
  }

  constructor(image: VideoPayload, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__src = image.src
    this.__assetId = image.assetId
    this.__assetUsageId = image.assetUsageId
    this.__alignment = image.alignment
    this.__widthPercentage = image.widthPercentage
  }

  exportJSON(): SerializedVideoNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "video",
      src: this.getSrc(),
      assetId: this.getAssetId(),
      assetUsageId: this.getAssetUsageId(),
      alignment: this.getAlignment(),
      widthPercentage: this.getWidthPercentage(),
    }
  }

  setAlignment(alignment: ElementFormatType): void {
    const writable = this.getWritable()
    writable.__alignment = alignment
  }

  setWidthPercentage(widthPercentage: number): void {
    const writable = this.getWritable()
    writable.__widthPercentage = widthPercentage
  }

  // View
  updateDOM(): false {
    return false
  }

  getSrc(): string {
    return this.__src
  }

  getAssetId(): string {
    return this.__assetId
  }

  getAssetUsageId(): string | undefined {
    return this.__assetUsageId
  }

  getAlignment(): ElementFormatType | undefined {
    return this.__alignment
  }

  getWidthPercentage(): number | undefined {
    return this.__widthPercentage
  }

  decorate(): JSX.Element {
    return (
      <EditorVideo
        nodeKey={this.getKey()}
        src={this.__src}
        assetId={this.__assetId}
        assetUsageId={this.__assetUsageId}
        alignment={this.__alignment}
        widthPercentage={this.__widthPercentage}
      />
    )
  }
}

export function $createVideoNode({
  src,
  assetId,
  assetUsageId,
  alignment,
  widthPercentage,
  key,
}: VideoPayload): VideoNode {
  return new VideoNode({ src, assetId, assetUsageId, alignment, widthPercentage }, key)
}

export function $isVideoNode(node: LexicalNode | null | undefined): node is VideoNode {
  return node instanceof VideoNode
}
