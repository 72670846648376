import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import {
  EDITOR_BLOCKS,
  EditorBlockType,
  getConvertableBlocks,
} from "@components/editor/config/LexicalNodes"
import { $createCalloutNode } from "@components/editor/plugins/callout/CalloutNode"
import { useEditorToolbarDropdown } from "@components/editor/plugins/toolbar/EditorToolbar"
import EditorToolbarButton from "@components/editor/plugins/toolbar/EditorToolbarButton"
import EditorBlockConvertItem from "@components/editor/plugins/toolbar/convert/EditorBlockConvertItem"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { $createCodeNode } from "@lexical/code"
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $createHeadingNode, $createQuoteNode, HeadingTagType } from "@lexical/rich-text"
import { $setBlocksType } from "@lexical/selection"
import { Popover } from "@material-ui/core"
import {
  $createParagraphNode,
  $getSelection,
  DEPRECATED_$isGridSelection as $isGridSelection,
  $isRangeSelection,
} from "lexical"
import { useMemo, useRef } from "react"

interface EditorToolbarConvertButtonProps {
  blockType: EditorBlockType
}

function EditorToolbarConvertButton({ blockType }: EditorToolbarConvertButtonProps) {
  const [editor] = useLexicalComposerContext()

  const { isOpen, onOpen, onClose } = useEditorToolbarDropdown()
  const { config } = useLexicalEditorContext()

  const convertableBlocks = useMemo(() => getConvertableBlocks(config), [config])

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const classes = useStyles()

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection) || $isGridSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || $isGridSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType === "bullet") {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType === "number") {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    }
  }

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || $isGridSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode())
        }
      })
    }
  }

  const formatCallout = () => {
    if (blockType !== "callout") {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || $isGridSelection(selection)) {
          $setBlocksType(selection, () => $createCalloutNode())
        }
      })
    }
  }

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        let selection = $getSelection()

        if ($isRangeSelection(selection) || $isGridSelection(selection)) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode())
          } else {
            const textContent = selection.getTextContent()
            const codeNode = $createCodeNode()
            selection.insertNodes([codeNode])
            selection = $getSelection()
            if ($isRangeSelection(selection)) selection.insertRawText(textContent)
          }
        }
      })
    }
  }

  return (
    <>
      <div>
        <EditorToolbarButton
          ref={buttonRef}
          isActive={false}
          onClick={onOpen}
          tooltip={"Turn Into"}
          icon={
            <div className={classes.activeIcon}>
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {EDITOR_BLOCKS[blockType].title}
              </DiscoText>
              <DiscoIcon icon={"arrow-down"} />
            </div>
          }
          ariaLabel={"Turn selected blocks into another type"}
        />
      </div>
      {buttonRef.current && (
        <Popover
          open={isOpen}
          onClose={onClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ paper: classes.popover }}
          anchorEl={buttonRef.current}
        >
          <DiscoText marginLeft={1} variant={"body-xs-500"} color={"text.secondary"}>
            {"Turn into"}
          </DiscoText>
          <ul className={classes.blockList}>
            {convertableBlocks.map((type) => (
              <EditorBlockConvertItem
                key={type}
                blockType={type}
                isSelected={type === blockType}
                onClick={() => handleConversion(type)}
              />
            ))}
          </ul>
        </Popover>
      )}
    </>
  )

  function handleConversion(type: EditorBlockType) {
    switch (type) {
      case "paragraph":
        formatParagraph()
        break
      case "h1":
      case "h2":
      case "h3":
        formatHeading(type)
        break
      case "number":
        formatNumberedList()
        break
      case "bullet":
        formatBulletList()
        break
      case "quote":
        formatQuote()
        break
      case "callout":
        formatCallout()
        break
      case "code":
        formatCode()
        break
      default:
        throw new Error(`Unhandled convertable block type: ${type}`)
    }

    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  popover: {
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.default,
    display: "grid",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1, 0, 0.5),
    width: "200px",
  },
  activeIcon: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
    paddingLeft: theme.spacing(0.5),

    "& svg:last-child": {
      width: "14px",
      height: "14px",
    },
  },
  blockList: {
    display: "grid",
  },
}))

export default EditorToolbarConvertButton
