import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useRef, useState } from "react"
import { Cropper, CropperRef } from "react-advanced-cropper"
import "react-advanced-cropper/dist/style.css"

interface ImageCropModalProps {
  isOpen: boolean
  onClose: () => void
  onCancel?: () => void
  imageSrc: string
  onCrop: (data: string) => void
  title?: string
  cropperProps?: ImageCropModalCropperProps
  parentSelector?: () => HTMLElement
}

function ImageCropModal({
  isOpen,
  onClose,
  onCancel,
  imageSrc,
  cropperProps,
  title,
  onCrop,
  parentSelector,
}: ImageCropModalProps) {
  const cropperRef = useRef<CropperRef>(null)
  const [isCropping, setIsCropping] = useState(false)
  const theme = useTheme()

  const { stencilProps, ...otherCropperProps } = cropperProps || {}

  const submitCrop = async () => {
    setIsCropping(true)
    const cropper = cropperRef.current
    if (cropper) {
      const canvas = cropper.getCanvas()
      if (canvas) {
        await onCrop(canvas.toDataURL())
      }
    }
    setIsCropping(false)
  }

  return (
    <DiscoModal
      modalContentLabel={"Crop Dialog"}
      customClassName={"image-crop-modal"}
      zIndex={theme.zIndex.modal + theme.zIndex.raise1}
      testid={"image-crop-modal"}
      isOpen={isOpen}
      onClose={onClose}
      title={title || "Crop Image"}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      parentSelector={parentSelector}
      body={
        <div style={{ height: 400, width: "100%" }}>
          <Cropper
            ref={cropperRef}
            src={imageSrc}
            className={"cropper"}
            stencilProps={stencilProps}
            {...otherCropperProps}
          />
        </div>
      }
      buttons={
        <>
          <DiscoButton
            testid={"ImageCropModal.cancelButton"}
            onClick={onCancel || onClose}
            color={"grey"}
            variant={"outlined"}
            disabled={isCropping}
          >
            {"Cancel"}
          </DiscoButton>

          <DiscoButton
            testid={"ImageCropModal.cropButton"}
            onClick={submitCrop}
            shouldDisplaySpinner={isCropping}
            disabled={isCropping}
          >
            {"Crop"}
          </DiscoButton>
        </>
      }
    />
  )
}

export default ImageCropModal
