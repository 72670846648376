import parse from "date-fns/parse"
import {
  DATE_FORMAT,
  hh_mm_a_TIME_FORMAT_REGEX,
} from "../../../../utils/time/timeConstants"

function isTimeInputValueValid(timeString: string) {
  return hh_mm_a_TIME_FORMAT_REGEX.test(timeString)
}

function getHourMinuteMeridiemFromTimeString(timeString: string) {
  const timeParts = timeString.match(hh_mm_a_TIME_FORMAT_REGEX)
  const defaultTime = { hours: "10", minutes: "00", meridiem: "AM" }

  return timeParts
    ? {
        /* eslint-disable no-magic-numbers */
        hours: timeParts[2],
        minutes: timeParts[3],
        meridiem: timeParts[4],
        /* eslint-enable no-magic-numbers */
      }
    : defaultTime
}

function formatTimeStringTo12hFormatWithMeridiem(timeString: string) {
  const { hours, minutes, meridiem } = getHourMinuteMeridiemFromTimeString(timeString)

  return `${hours}:${minutes} ${meridiem}`
}

function combineDateAndTimeString(date: Date, timeString: string): Date {
  const parsedDate = parse(
    formatTimeStringTo12hFormatWithMeridiem(timeString),
    DATE_FORMAT.LONG_TIME_FORMAT,
    date
  )

  return parsedDate
}

/* eslint-disable no-magic-numbers */
// eslint-disable-next-line complexity
function parseTime(time: string, format = "g:i A") {
  const num = time.replace(/[^0-9]/g, "")

  let hour
  let minute
  let isPM = time.match(/p/i) !== null

  // Parse for hour and minute
  switch (num.length) {
    case 4: {
      hour = parseInt(num[0] + num[1], 10)
      minute = parseInt(num[2] + num[3], 10)
      break
    }

    case 3: {
      hour = parseInt(num[0], 10)
      minute = parseInt(num[1] + num[2], 10)
      break
    }

    case 2:
    case 1: {
      hour = parseInt(num[0] + (num[1] || ""), 10)
      minute = 0
      break
    }

    default:
      return ""
  }

  // Make sure hour is in 24 hour format
  if (isPM && hour > 0 && hour < 12) {
    hour += 12
  }

  // Force pm for hours between 13:00 and 23:00
  if (hour >= 13 && hour <= 23) {
    isPM = true
  }

  // Keep within range
  if (hour <= 0 || hour >= 24) {
    hour = 0
  }
  if (minute < 0 || minute > 59) {
    minute = 0
  }

  // Format output
  return (
    format
      // 12 hour without leading 0
      .replace(/g/g, hour === 0 ? "12" : "g")
      .replace(
        /g/g,
        hour > 12
          ? (hour - 12).toString().padStart(2, "0")
          : hour.toString().padStart(2, "0")
      )
      // 24 hour without leading 0
      .replace(/G/g, String(hour))
      // 12 hour with leading 0
      .replace(
        /h/g,
        String(
          // eslint-disable-next-line no-nested-ternary
          hour.toString().length > 1
            ? hour > 12
              ? hour - 12
              : hour
            : `0${hour > 12 ? hour - 12 : hour}`
        )
      )
      // 24 hour with leading 0
      .replace(/H/g, String(hour.toString().length > 1 ? hour : `0${hour}`))
      // minutes with leading zero
      .replace(/i/g, String(minute.toString().length > 1 ? minute : `0${minute}`))
      // simulate seconds
      .replace(/s/g, "00")
      // lowercase am/pm
      .replace(/a/g, isPM ? "pm" : "am")
      // lowercase am/pm
      .replace(/A/g, isPM ? "PM" : "AM")
  )
}
/* eslint-enable no-magic-numbers */

export {
  formatTimeStringTo12hFormatWithMeridiem,
  isTimeInputValueValid,
  combineDateAndTimeString,
  parseTime,
}
