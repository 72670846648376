// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/FloatingEditorToolbarPlugin/index.tsx
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import {
  EditorBlockType,
  getConvertableBlocks,
} from "@components/editor/config/LexicalNodes"
import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import TextToolbar from "@components/editor/plugins/text-toolbar/TextToolbar"
import { $isCodeHighlightNode } from "@lexical/code"
import { $isLinkNode } from "@lexical/link"
import { $isListNode, ListNode } from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $isHeadingNode } from "@lexical/rich-text"
import { $getSelectionStyleValueForProperty } from "@lexical/selection"
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from "@lexical/utils"
import { useTheme } from "@material-ui/core"
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  ElementFormatType,
  LexicalEditor,
  TextFormatType,
  TextNode,
} from "lexical"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"

function useFloatingTextToolbar(
  editor: LexicalEditor,
  anchorElem: HTMLElement
): JSX.Element | null {
  const theme = useTheme()

  const { config } = useLexicalEditorContext()
  const convertableBlocks = useMemo(() => getConvertableBlocks(config), [config])

  const [isText, setIsText] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [alignment, setAlignment] = useState<ElementFormatType>("left")
  const [fontColor, setFontColor] = useState<string>(theme.palette.text.primary)
  const [highlightColor, setHighlightColor] = useState<string>(
    theme.palette.background.paper
  )

  const [blockType, setBlockType] = useState<EditorBlockType>("paragraph")

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return
      }
      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = LexicalUtils.getSelectedNode(selection)

      // Update block type
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = (
            $isHeadingNode(element) ? element.getTag() : element.getType()
          ) as EditorBlockType
          if (convertableBlocks.includes(type) || type === "check") {
            setBlockType(type)
          }
        }
      }

      // Update text format
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))
      setIsCode(selection.hasFormat("code"))

      setFontColor(
        $getSelectionStyleValueForProperty(selection, "color", theme.palette.text.primary)
      )
      setHighlightColor(
        $getSelectionStyleValueForProperty(
          selection,
          "background-color",
          theme.palette.background.paper
        )
      )

      // Update links
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      // Check if text is selected
      if (
        !$isCodeHighlightNode(selection.anchor.getNode()) &&
        selection.getTextContent() !== ""
      ) {
        setIsText($isTextNode(node))
      } else {
        setIsText(false)
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, "")
      if (!selection.isCollapsed() && rawTextContent === "") {
        setIsText(false)
      }

      // Update alignment
      setAlignment(
        ($isElementNode(node) ? node.getFormatType() : parent?.getFormatType()) || "left"
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  useEffect(() => {
    document.addEventListener("selectionchange", updatePopup)
    return () => {
      document.removeEventListener("selectionchange", updatePopup)
    }
  }, [updatePopup])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      }),
      // Remove any unsupported inline formats
      editor.registerNodeTransform(TextNode, (node: TextNode) => {
        for (const format of [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
        ] as TextFormatType[]) {
          if (config.inlineTools.has(format)) continue
          // If the node has an inline format that is not in the config, remove it
          if (node.hasFormat(format)) node.toggleFormat(format)
        }

        // If the node has a color format that is not in the config, remove it
        if (node.getStyle() && !config.inlineTools.has("color")) {
          node.setStyle("")
        }
      })
    )
  }, [editor, updatePopup, config.inlineTools])

  if (!isText || isLink) return null

  return createPortal(
    <TextToolbar
      editor={editor}
      anchorElem={anchorElem}
      isLink={isLink}
      isBold={isBold}
      isItalic={isItalic}
      isStrikethrough={isStrikethrough}
      isUnderline={isUnderline}
      isCode={isCode}
      fontColor={fontColor}
      highlightColor={highlightColor}
      alignment={alignment}
      blockType={blockType}
    />,
    anchorElem
  )
}

interface EditorToolbarPluginProps {
  anchorElem?: HTMLElement
}

function TextToolbarPlugin({
  anchorElem = document.body,
}: EditorToolbarPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  return useFloatingTextToolbar(editor, anchorElem)
}

export default TextToolbarPlugin
