import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useEditorToolbarDropdown } from "@components/editor/plugins/toolbar/EditorToolbar"
import EditorToolbarButton from "@components/editor/plugins/toolbar/EditorToolbarButton"
import { DiscoIcon, DiscoIconKinds } from "@disco-ui"
import { Popover, capitalize } from "@material-ui/core"
import { ElementFormatType } from "lexical"
import { useRef } from "react"

const ALIGNMENT_OPTIONS: ElementFormatType[] = ["left", "center", "right"]

interface EditorToolbarTextAlignButtonProps {
  alignment: ElementFormatType
  onChange: (alignment: ElementFormatType) => void
}

function EditorToolbarTextAlignButton({
  alignment,
  onChange,
}: EditorToolbarTextAlignButtonProps) {
  const { isOpen, onOpen, onClose } = useEditorToolbarDropdown()

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const classes = useStyles()

  return (
    <>
      <div>
        <EditorToolbarButton
          ref={buttonRef}
          isActive={false}
          onClick={onOpen}
          tooltip={"Align"}
          icon={
            <div className={classes.activeIcon}>
              <DiscoIcon icon={`align-${alignment}` as DiscoIconKinds} />
              <DiscoIcon icon={"arrow-down"} />
            </div>
          }
          ariaLabel={"Align selected text"}
        />
      </div>
      {buttonRef.current && (
        <Popover
          open={isOpen}
          onClose={onClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          classes={{ paper: classes.popover }}
          anchorEl={buttonRef.current}
        >
          {ALIGNMENT_OPTIONS.map((option) => (
            <EditorToolbarButton
              key={option}
              isActive={option === alignment}
              onClick={() => {
                onChange(option)
                onClose()
              }}
              tooltip={`Align ${capitalize(option)}`}
              icon={`align-${option}` as DiscoIconKinds}
              ariaLabel={`Align selected text to the ${option}`}
            />
          ))}
        </Popover>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  popover: {
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.default,
    display: "flex",
    padding: theme.spacing(0.25),
    marginTop: theme.spacing(0.5),
  },
  activeIcon: {
    display: "flex",
    alignItems: "center",

    "& svg:last-child": {
      width: "14px",
      height: "14px",
    },
  },
}))

export default EditorToolbarTextAlignButton
