import { GlobalID } from "@/relay/RelayTypes"
import EditorPoll from "@components/editor/plugins/poll/EditorPoll"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, Spread, type LexicalNode, type NodeKey } from "lexical"

export type PollPayload = { webFormId: GlobalID }

type SerializedPollNode = Spread<PollPayload, SerializedDecoratorBlockNode>

export class PollNode extends DecoratorBlockNode {
  __webFormId: GlobalID

  constructor({ webFormId }: PollPayload, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__webFormId = webFormId
  }

  static getType(): string {
    return "poll"
  }

  static importJSON(serializedNode: SerializedPollNode): PollNode {
    return $createPollNode(serializedNode)
  }

  exportJSON(): SerializedPollNode {
    return {
      ...super.exportJSON(),
      type: "poll",
      webFormId: this.getWebFormId(),
    }
  }

  static clone(node: PollNode): PollNode {
    return new PollNode({ webFormId: node.__webFormId }, node.__key, node.__format)
  }

  setWebFormId(webFormId: GlobalID): void {
    this.getWritable().__webFormId = webFormId
  }

  getWebFormId(): GlobalID {
    return this.getLatest().__webFormId
  }

  decorate(): JSX.Element {
    return <EditorPoll nodeKey={this.getKey()} webFormId={this.__webFormId} />
  }
}

export function $createPollNode(payload: PollPayload): PollNode {
  return new PollNode(payload)
}

export function $isPollNode(node: LexicalNode | null | undefined): node is PollNode {
  return node instanceof PollNode
}
