import makeUseStyles from "@assets/style/util/makeUseStyles"
import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import EditorToolbarButton from "@components/editor/plugins/toolbar/EditorToolbarButton"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { TOGGLE_LINK_COMMAND } from "@lexical/link"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { Theme, useMediaQuery } from "@material-ui/core"
import { useCallback } from "react"

interface EditorToolbarLinkButtonProps {
  isLink: boolean
}

function EditorToolbarLinkButton({ isLink }: EditorToolbarLinkButtonProps) {
  const [editor] = useLexicalComposerContext()

  const insertLink = useCallback(() => {
    if (isLink) editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    else editor.dispatchCommand(TOGGLE_LINK_COMMAND, LexicalUtils.EDITOR_LINK_PLACEHOLDER)
  }, [editor, isLink])

  const classes = useStyles()
  const isXsScreen = useMediaQuery((t: Theme) => t.breakpoints.down("xs"))

  return (
    <EditorToolbarButton
      isActive={isLink}
      onClick={insertLink}
      tooltip={"Add Link"}
      icon={
        <div className={classes.icon}>
          <DiscoIcon icon={"link"} />
          {!isXsScreen && (
            <DiscoText
              variant={"body-sm"}
              color={isLink ? "text.primary" : "text.secondary"}
            >
              {"Link"}
            </DiscoText>
          )}
        </div>
      }
      ariaLabel={"Toggle text as a link"}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  icon: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
    paddingRight: theme.spacing(0.5),
  },
}))

export default EditorToolbarLinkButton
