import makeUseStyles from "@assets/style/util/makeUseStyles"
import { EDITOR_BLOCKS, EditorBlockType } from "@components/editor/config/LexicalNodes"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"

interface EditorBlockConvertItemProps {
  blockType: EditorBlockType
  onClick: () => void
  isSelected?: boolean
}

function EditorBlockConvertItem({
  blockType,
  onClick,
  isSelected,
}: EditorBlockConvertItemProps) {
  const classes = useStyles()
  const { title, icon } = EDITOR_BLOCKS[blockType]

  const theme = useTheme()

  return (
    <DiscoContainerButton
      className={classes.item}
      onClick={onClick}
      onMouseDown={(e) => {
        // For Safari where the onClick was not being fired
        e.preventDefault()
      }}
    >
      {icon && (
        <div className={classes.iconWrapper}>
          <DiscoIcon icon={icon} height={18} width={18} />
        </div>
      )}
      <DiscoText variant={"body-sm"}>{title}</DiscoText>
      {isSelected && <DiscoIcon color={theme.palette.text.primary} icon={"check"} />}
    </DiscoContainerButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    cursor: "pointer",
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    gap: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.default,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 0.5),

    background: theme.palette.background.paper,

    "&:hover": {
      background: theme.palette.groovy.neutral[200],

      "& div svg": {
        color: theme.palette.text.primary,
      },
    },
  },
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.default,
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
}))

export default EditorBlockConvertItem
