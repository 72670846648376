import makeUseStyles from "@assets/style/util/makeUseStyles"
import useDisclosure from "@utils/hook/useDisclosure"
import classNames from "classnames"
import { createContext, forwardRef, useContext } from "react"

export const EditorToolbarContext = createContext<{
  onDropdownOpen?: () => void
  onDropdownClose?: () => void
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ onDropdownOpen: () => {}, onDropdownClose: () => {} })

export const useEditorToolbarContext = () => useContext(EditorToolbarContext)

export function useEditorToolbarDropdown() {
  const { onDropdownOpen, onDropdownClose } = useEditorToolbarContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return { isOpen, onOpen: handleOpen, onClose: handleClose, onToggle: handleToggle }

  function handleOpen() {
    onOpen()
    onDropdownOpen?.()
  }

  function handleClose() {
    onClose()
    onDropdownClose?.()
  }

  function handleToggle() {
    if (isOpen) handleClose()
    else handleOpen()
  }
}

interface EditorToolbarProps {
  children?: React.ReactNode
  className?: string
  onDropdownOpen?: () => void
  onDropdownClose?: () => void
}

const EditorToolbar = forwardRef<HTMLDivElement, EditorToolbarProps>(
  ({ children, className, onDropdownOpen, onDropdownClose }, ref) => {
    const classes = useStyles()

    return (
      <EditorToolbarContext.Provider value={{ onDropdownOpen, onDropdownClose }}>
        <div ref={ref} className={classNames(classes.toolbar, className)}>
          {children}
        </div>
      </EditorToolbarContext.Provider>
    )
  }
)

interface EditorToolbarGroupProps {
  children?: React.ReactNode
  className?: string
}

export function EditorToolbarGroup({ children, className }: EditorToolbarGroupProps) {
  const classes = useStyles()

  return <div className={classNames(classes.group, className)}>{children}</div>
}

interface EditorToolbarDividerProps {
  className?: string
}

export function EditorToolbarDivider({ className }: EditorToolbarDividerProps) {
  const classes = useStyles()

  return <div className={classNames(classes.divider, className)} />
}

const useStyles = makeUseStyles((theme) => ({
  toolbar: {
    display: "grid",
    gridAutoFlow: "column",
    background: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.default,

    "& *": {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
  },
  group: {
    display: "flex",
    padding: theme.spacing(0.25),
  },
  divider: {
    width: "1px",
    height: "100%",
    background: theme.palette.groovy.neutral[300],
  },
}))

export default EditorToolbar
