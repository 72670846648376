/**
 * @generated SignedSource<<fb5f89e326c902c1cfd9a123ea15c93e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentEmojiButton_ContentReactionFragment$data = {
  readonly id: string;
  readonly hasViewerReacted: boolean;
  readonly emojiCode: string;
  readonly emojiAlias: string;
  readonly emojiCount: number;
  readonly " $fragmentType": "ContentEmojiButton_ContentReactionFragment";
};
export type ContentEmojiButton_ContentReactionFragment$key = {
  readonly " $data"?: ContentEmojiButton_ContentReactionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentEmojiButton_ContentReactionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentEmojiButton_ContentReactionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerReacted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emojiCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emojiAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emojiCount",
      "storageKey": null
    }
  ],
  "type": "ContentReaction",
  "abstractKey": null
};

(node as any).hash = "203eadc940f6b64049d574dec581600b";

export default node;
